.IllegalTrade-Container{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--papyrus);
}

.IllegalTrade-Container > h1 {
    color: var(--moss);
    width: 644px;
    margin-right: auto;
    margin-left: auto;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
}

.IllegalTrade-Container > p {
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 996px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
}

.IllegalTrade-Container > p > a{
    color: var(--sunset-orange);
    transition: all 200ms ease-in-out;
}

.IllegalTrade-Container > p > a:hover{
    color: var(--moss-animation);
}

@media screen and (max-width: 1089px) {
    .IllegalTrade-Container{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
        width: 100%;
    }

    .IllegalTrade-Container > h1 {
        color: var(--moss);
        width: 100% !important;
        padding-left: 43.5px;
        padding-right: 43.5px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
    }
    
    .IllegalTrade-Container > p {
        max-width: 100% !important;
        padding-left: 24px;
        padding-right: 24px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;   
    }
    
}