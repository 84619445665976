.WhoWeAre-Body{
    background-color: var(--papyrus);
    margin: 0;
    padding: 0;
}

.WhoWeAre-Body > .TitleSection-body {
    height: fit-content;
    padding-bottom: 0;

}

.WhoWeAre-Body > .TitleSection-body {
    padding: 0;
}

.WhoWeAre-Body > .TitleSection-body > div {
    height: fit-content;
}

.WhoWeAre-Body > .TitleSection-body > div > h1 {
    padding-top: 120px;
}

.WhoWeAre-Body > .TitleSection-body > div > p {
    margin-top: 71.5px;
    margin-bottom: 34.5px;
    width: 796px;
    height: 148px;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
}

.WhoWeAre-Body-Team {
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.WhoWeAre-Body-Team-Background{
    background-color: var(--moss);
}

#WhoWeAre-Body-Team-Trustees {
    background-color: var(--moss);
}

#WhoWeAre-Body-Team-Trustees > h1  {
    color: var(--papyrus);
}

#WhoWeAre-Body-Team-Trustees > div > div > h1  {
    color: var(--papyrus);
}


.WhoWeAre-Body-Team > h1{
    color: var(--moss);
    padding-bottom: calc(var(--padding-bottom)/2);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
}

.WhoWeAre-Body-Team-TeamContainer { 
    margin-left: 48px;
    margin-right: 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 48px;
    row-gap: var(--padding-bottom);

}
.WhoWeAre-Body-Team-TeamContainer > div {
    max-width: 340px;
    flex: 33%;
}

@media screen and (max-width: 1089px) {
    .WhoWeAre-Body-Team-TeamContainer { 
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media screen and (max-width: 780) {

    .WhoWeAre-Body-Team {
        padding-top: var(--padding-top-mobile);

    }

    .WhoWeAre-Body-Team > h1{
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
    }

    .WhoWeAre-Body-Team-TeamContainer { 
        padding-left: 24px;
        padding-right: 24px;
    }

    .WhoWeAre-Body > .TitleSection-body > div > p {
        width: 311px;
        height: fit-content;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        padding: 0;
        padding-top: 16px;
        padding-bottom: 32px;
        margin: 0;
    }
}
