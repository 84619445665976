.PangolinsFactsCarousel-Body{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: var(--padding-bottom);
}

.PangolinsFactsCarousel-Body > h1 {
    font-family: Inter;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: center;
    color: var(--moss);
    padding: 0px;
    margin: 0px;
}

.PangolinsFactsCarousel-Body > p {
    margin-left: auto;
    margin-right: auto;
    max-width: 898px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: calc(var(--padding-top)/2);
    margin-bottom: calc(var(--padding-bottom)/2);
}

.PangolinsFactsCarousel-Body-Carousel{
    width: 946px;
    height: 602px;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    border-radius: 12px;
}


.PangolinsFactsCarousel-Body-Carousel-Item {
    border-radius: 12px;
}

.PangolinsFactsCarousel-Body-Carousel-Item > div {
    border-radius: 12px 12px 0px 0px;
}

.PangolinsFactsCarousel-Body-Carousel-Item {
    padding-left: 24px;
    padding-right: 24px;
    width: 946px;
    height: fit-content;
    display: flex;
}

.PangolinsFactsCarousel-Body-Carousel > .slick-list > .slick-track > :nth-child(n) > div > div > div  {
    display: block;
    width: 100%;
    background-color: #F9B9B3;
}

.PangolinsFactsCarousel-Body-Carousel > .slick-list > .slick-track > :nth-child(2n) > div > div > div  {
    display: block;
    background-color: #F3D956;
}

.PangolinsFactsCarousel-Body-Carousel > .slick-dots {
    top: 339px;
}

.PangolinsFactsCarousel-Body-Carousel-Item > div > img {
    max-width: 100%;
    padding: 5%;
    max-height: 354px;
    height: fit-content;
    margin-right: auto;
    margin-left: auto;
    border-radius: 12px;
    overflow: hidden !important;
}

.PangolinsFactsCarousel-Body-Carousel-Item > span {
    display: flex;
    background-color: white;
    border-radius: 0px 0px 12px 12px;
    height: fit-content;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 72px;
    padding-right: 72px;
}

.PangolinsFactsCarousel-Body-Carousel-Item > span > p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

@media screen and (max-width: 1080px) {

    .PangolinsFactsCarousel-Body{
        padding-right: 24px;
        padding-left: 24px;
        padding-bottom: var(--padding-bottom-mobile);
        padding-top: var(--padding-top-mobile);
    }

    .PangolinsFactsCarousel-Body > h1 {
        margin: 0px;
        padding: 0px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 4px;
    }

    .PangolinsFactsCarousel-Body > p {
        margin-top: 0px;
        padding-top: 0px;
    }
    
    .PangolinsFactsCarousel-Body-Carousel{
        width: 100%;
        height: fit-content;
    }

    .PangolinsFactsCarousel-Body-Carousel-Item {
        width: 100%;
        height: fit-content;
    }

    .PangolinsFactsCarousel-Body-Carousel-Item > span {
        height: fit-content;
        padding-left: 0px;
        padding-right: 0px;
    }

    .PangolinsFactsCarousel-Body-Carousel-Item > span > p {
        padding-top: 34px;
        padding-bottom: 34px;
        padding-left: 10px;
        padding-right: 10px;
        height: fit-content;
    }
}