.ArtGallery-Title-body{
    height: fit-content;
    background-color: var(--moss);
    padding: auto;
}

.ArtGallery-Title-body-flex{
    padding-top: var(--padding-top);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    margin: auto;
}

.ArtGallery-Title-body-flex-title{
    margin: 0;
    padding: 0;
    font-weight: 800;
    color: var(--papyrus);
    font-size: 72px;
    text-align: center;
}

.ArtGallery-Title-body-flex-description{
    margin: 0;
    margin-top: calc(var(--padding-top)/2);
    padding: 0;
    width: 613px;
    font-weight: 400;
    color: var(--papyrus);
    font-size: 18px;
    text-align: center;
}

.ArtGallery-Title-body-flex-button{
    margin: auto;
    margin-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
}

@media screen and (max-width: 1089px){

    .ArtGallery-Title-body-flex{
        padding-top: var(--padding-top-mobile);
    }

    .ArtGallery-Title-body-flex-title{
        font-size: 40px;
        font-weight: 800;
        line-height: 54px;
        letter-spacing: 0em;
    }

    .ArtGallery-Title-body-flex-description{
        margin-top: calc(var(--padding-top)/2);      
        width: 311px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        text-align: center;
    }

    .ArtGallery-Title-body-flex-button{
        margin: auto;
        margin-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }
}
