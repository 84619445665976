@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
*{
  font-family: 'Inter', sans-serif;}

:root{
  --sunset-orange: #f15621;
  --moss: #37402c;
  --moss-animation: #545a46;
  --papyrus: #ece5d1;
  --papyrus-animation: #cfcbb7;
  --rose-tan: #f9b9b3;
  --canary-yellow: #f3d956;
  --ross: #f9b9b3;
  --padding-top: 48px;
  --padding-bottom: 48px;
  --padding-top-mobile: 24px;
  --padding-bottom-mobile: 24px;
}

body {
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; 
}

.mountedStyle {
  -webkit-animation: inAnimation 200ms ease-in;
          animation: inAnimation 200ms ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.unmountedStyle {
  -webkit-animation: outAnimation 200ms ease-out;
          animation: outAnimation 200ms ease-out;
}

@-webkit-keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (max-width: 768px) {

  .Pangolins-Buttons-Container{
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  
  .Pangolins-Buttons-Container > div > a > button {
    width: 327px !important;
    margin-left: 24px;
    margin-right: 24px;
  }
  .Pangolins-Buttons-Container > div > button {
    width: 327px !important;
    margin-left: 24px;
    margin-right: 24px;
  }
  .Pangolins-Buttons-Container > a > button {
    width: 327px !important;
    margin-left: 24px;
    margin-right: 24px;
  }
  .Pangolins-Buttons-Container > button {
    width: 327px !important;
    margin-left: 24px;
    margin-right: 24px;
  }
}
  

/* Ant carousel properties */
/* .ant-carousel .slick-dots li{
  background-color: #BCB7AA;
  width: 12.5px !important;
  height: 12.5px !important;
  border-radius: 50%
}

.ant-carousel .slick-dots li button{
  background-color: var(--moss);
  width: 12.5px !important;
  height: 12.5px !important;
  border-radius: 50% !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: var(--moss) !important;
  width: 12.5px !important;
  height: 12.5px !important;
  border-radius: 50%
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Header-desktop{
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: var(--moss);
}

.Header-desktop-left{
    padding-left: 110px;
    margin-top: auto;
    margin-bottom: auto;
}

.Header-desktop-right{
    display: flex;
    flex-direction: row;
    visibility: visible;
    margin-left: auto;
    padding-right: 110px;
    height: 94px;
}




.Header-desktop h1{
  /* position: absolute; */
  color: white;
}

#P {
  margin-top: 2vh;
  margin-left: 1vh;
}

.Header-desktop-logo-img{
    margin-top: auto;
    margin-bottom: auto;
    height: 40px;
}

.Header-desktop-logo-mobile-img{
    display: none;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 10px;
    height: 40px;
}

.Header-desktop-button{
    margin-top: auto;
    margin-bottom: auto;
    /* !important until we figure out color scheme */
    color: #ECE5D1 !important;
}

.Header-desktop-button-donate{
  margin-top: auto;
  margin-bottom: auto;
  background-color: #ECE5D1; /*does not currently work*/
  color: black;
}

.Header-mobile{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    visibility: hidden;
    height: 0rem;
    background-color: #37402C;
    z-index: 1000;
}

.Header-mobile-logo{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: 16px;
}

.Header-mobile-logo > *{
    height: 28px;
}

.Header-mobile-logo-hidden{
    position: absolute;
    top: 10px;
    left: 24px;
    height: 28px;
}

.Header-mobile-icon{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 16px;
    margin-left: auto;
    height: 20px;
    cursor: pointer;
}


.ant-drawer-close{
    position: absolute;
    color: white !important;
    top: 14px;
    right: 24px;
    padding: 0 !important;
    margin: 0 !important;
    width: 20px;
    height: 20px;
}


.Header-desktop-right > span{
    margin-right: 6px;
}

.Header-mobile-donate {
    width: calc(100% - 48px);
    margin-left: auto;
    margin-right: auto;
}

.Header-mobile-donate > *{
    margin-right: 10px;
}

.Header-mobile-donate > * > * {
    height: 48px !important;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.ant-menu-submenu-arrow > *{
    width: 16px !important;
}

.ant-menu-title-content{
    color: var(--papyrus);
}

.ant-menu-title-content > *{
    color: var(--papyrus);
}

.ant-menu-item-selected > *{
    color: var(--moss-animation) !important;
}

.ant-menu-item-selected > * > * {
    color: var(--moss-animation) !important;
}


.ant-drawer-header-close-only {
    margin: 0;
    padding: 0;
    height: 48px;
    color: white !important;
    border-bottom: 0px !important;
}
.ant-drawer-body{
    color: white;
    padding: 0 !important;
    margin: 0 !important;
}

.ant-drawer-body > div > .Buttons-Link > .Buttons-Filled{
    width: calc(100vw - 48px);
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 14px;
    margin-bottom: 14px;
}

.ant-menu-sub{
    background-color: rgba(236, 229, 209, 0.08) !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    letter-spacing: 0em !important;
    text-align: left;
}

.ant-menu-item{
    height: 64px !important;
}

.ant-menu-item-active{
    color: rgba(249, 185, 179, 1) !important;
}
.ant-menu-submenu-arrow{
    width: 20px;
    height: 20px;
}
.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover {
    color: rgba(249, 185, 179, 1) !important;
}


.ant-menu-submenu-title{
    height: 64px !important;
}

.ant-menu-item-selected{
    color: var(--moss) !important;
    background-color: var(--papyrus) !important;
}
.ant-drawer-body > *{
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 32px !important;
    letter-spacing: 0em !important;
    text-align: left;
    color: white;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #37402C !important;
}


.ant-dropdown-menu-title-content{
    color: var(--moss) !important;
}

.ant-dropdown-arrow{
    visibility: hidden;
    border-color: var(--papyrus) !important;
}

.ant-dropdown-menu-item-active {
    background-color: white !important;
}

.Header-Desktop-Dropdown{
    width: 100% !important;; 
    top: -14px !important;
    border-radius: 0px !important;
    background-color: var(--papyrus) !important;   
}

.Header-Desktop-Dropdown > *{
    height: 75px;
    width: 100%;
}


@media screen and (max-width: 1400px){
    .Header-desktop-right{
        padding-right: 24px;
    }
    .Header-desktop-left{
        padding-left: 24px;
    }
}


@media screen and (max-width: 1250px){

    .Header-desktop{
        justify-content: space-evenly;
    }

    .Header-desktop-logo-img{
        display: none;
    }
    
    .Header-desktop-logo-mobile-img{
        display: block;
    }
    

   
}

@media screen and (max-width: 1150px){

    .Header-desktop-right > span{
        margin-right: 0px;
    }
    
    .Header-desktop{
        display: none;
    }

    .Header-desktop-logo-mobile-img{
        display: none;
    }

    .Header-desktop-right{
        visibility: hidden;
    }

    .Header-mobile{
        visibility: visible;
        height: 3rem;
    }
}

.Buttons-Unfilled{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--papyrus);
}

.Buttons-Unfilled-Header{
    padding: 0px;
    height: 94px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--papyrus);
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Header:hover{
    background-color: var(--moss-animation) !important;
}


.Buttons-Unfilled-Header:hover > *{
    color: var(--ross) !important;
}


.Buttons-Unfilled-Header-Dropdown-Menu{
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    flex-direction: column;
    margin-top: -100px;
    border: 1px solid var(--moss-animation);
    border-top: 0px;
    transition: all 200ms ease-in-out;
    opacity: 0;
}

.Buttons-Unfilled-Header-Dropdown-Menu-Item{
    color: var(--papyrus);
    background-color: var(--moss);
    display: flex;
    justify-content: center;
    height: 75px;
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Header-Dropdown-Menu-Item > p{
    margin-top: auto;
    margin-bottom: auto;
    max-width: 124px;
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Header-Text{
    background-color: transparent !important;
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Header-Dropdown-Menu-Item:hover{
    background-color: var(--moss-animation);
    color: var(--ross);
}


.Buttons-Unfilled-Header > div {
    margin: 0px;
    display: flex;
    height: 94px;
    width: 100%;
    padding-left: 24px !important;
    padding-right: 24px !important;
    position: -webkit-sticky;
    position: sticky;
    z-index: 10;
}

.Buttons-Unfilled-Header > div > p {
    margin: 0px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: auto;
    margin-bottom: auto;
    background-color: var(--moss);
}

.Buttons-Unfilled-Header > div > p > img{
    /* https://codepen.io/sosuke/pen/Pjoqqp */
    -webkit-filter: invert(92%) sepia(17%) saturate(247%) hue-rotate(350deg) brightness(102%) contrast(85%);
            filter: invert(92%) sepia(17%) saturate(247%) hue-rotate(350deg) brightness(102%) contrast(85%);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Header:hover > span {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    margin-top: 0px;
    flex-direction: column;
    opacity: 1;
}

.Buttons-Unfilled-Header:hover > div > p {
    background-color: var(--moss-animation);
}

.Buttons-Unfilled-Header:hover{
    background-color: transparent;
}

.Buttons-Unfilled-Header:hover > div {
    color: var(--ross);
    background-color: var(--moss-animation);
}

.Buttons-Unfilled-Header:hover > div > p > img{
    /* https://codepen.io/sosuke/pen/Pjoqqp */
    -webkit-filter: invert(76%) sepia(30%) saturate(370%) hue-rotate(316deg) brightness(99%) contrast(98%);
            filter: invert(76%) sepia(30%) saturate(370%) hue-rotate(316deg) brightness(99%) contrast(98%); 
    -webkit-transform: rotate(180deg); 
            transform: rotate(180deg);
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Outlined{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: transparent;
    border: none;
    border-radius: 6px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    color: var(--moss);
    border: 1px solid var(--papyrus);
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Inversed{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--moss);
    border-radius: 6px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid var(--moss);
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Inversed > img{
    padding-left: 8px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 12px;
}

.ButtonLinkArrow {
    font-family: Inter;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    letter-spacing: 0em !important; 
    text-align: center !important;
}



.Buttons-Unfilled-Inversed:hover, .Buttons-Unfilled-Inversed:focus{
    background-color: var(--papyrus-animation);
    border: 1px solid var(--moss-animation);
}

.Buttons-Unfilled-Inversed > span{
    color: var(--moss);
}

.Buttons-Filled{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--papyrus);
    border: none;
    border-radius: 6px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    color: var(--moss);
    border: 1px solid var(--papyrus);
    transition: all 200ms ease-in-out;
}

.Buttons-Filled-Header{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--papyrus);
    border: none;
    border-radius: 6px;
    margin-top: 23px;
    margin-bottom: 23px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    color: var(--moss);
    border: 1px solid var(--papyrus);
    transition: all 200ms ease-in-out;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    
}

.Buttons-Filled:hover,.Buttons-Filled:focus{
    background-color: var(--papyrus-animation);
    border: 1px solid var(--papyrus-animation);
}

.Buttons-Filled-Inversed{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    background-color: var(--moss);
    border: none;
    border-radius: 6px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--moss);
    transition: all 200ms ease-in-out;
}

.Buttons-Filled-Inversed:hover, .Buttons-Filled-Inversed:focus{
    background-color: var(--moss-animation);
    border: 1px solid var(--moss-animation);

}

.Buttons-Filled-Inversed > span{
    color: var(--papyrus)
}

.Buttons-Link{
    margin-top: auto;
    margin-bottom: auto;
}

.Buttons-Unfilled-Text{
    margin-left: 24px;
    margin-right: 24px;
    color: var(--papyrus);
}

.Buttons-Unfilled-Text > img {
    width: 14px;
    height: auto;
    margin-bottom: 2.5px;
    margin-left: 14px;
    color: var(--papyrus);
}

.Buttons-Filled-Text{
    color: var(--moss);
}


/* elly */
.Buttons-Line{
    
    background-color: transparent;
    text-align: center;   
    cursor: pointer;
    width: 24px;
    height: 26px;
    transition: all 200ms ease-in-out;
    border: none;
    border: 1px solid transparent;

}


.Buttons-Line:hover{
    background-color: var(--papyrus-animation);
    border-radius: 2px;
}

.Buttons-Line:focus{
    background-color: var(--papyrus-animation);
    border: 1px solid var(--moss);
    border-radius: 2px;
}

.Buttons-Line-Focused{
    text-align: center;   
    cursor: pointer;
    width: 24px;
    height: 26px;
    transition: all 200ms ease-in-out;
    border: none;
    background-color: var(--papyrus-animation);
    border: 1px solid var(--moss);
    border-radius: 2px;
}



.Buttons-Right-Arrow, .Buttons-Left-Arrow{
    width: 24px;
    height: 26px;
    border: none;
    background-color: transparent;
}

.Buttons-Right-Arrow, .Buttons-Left-Arrow > img{
    margin: auto;
}

.Buttons-Filled-Header-Animated{
    width: 100px;
    height: 40px;
    background-color: var(--papyrus);
    border: none;
    border-radius: 6px;
    margin-top: 25px;
    margin-bottom: 23px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    color: var(--moss);
    border: 1px solid var(--papyrus);
    transition: all 200ms ease-in-out;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    transition: all 200ms ease-in-out;
}

.Buttons-Filled-HeaderContainer-Animated:hover  > .Buttons-Filled-Header-Animated {
    width: 150px;
}

.Buttons-Filled-HeaderContainer-Animated:hover > * > .Buttons-Filled-Header-Animated-Preview {
    color: var(--papyrus);
    height: 0;
    transition: all 200ms ease-in-out;;
}


.Buttons-Filled-Header-Animated-HiddenText {
    height: 0px;
    color: transparent;
    display: block;
    transition: all 0ms ease-in-out;;
    text-overflow: ellipsis;
    white-space: nowrap; 
    margin: 0px;
    padding: 0px;
    line-height: fit-content;
    z-index: -100;

    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
}

.Buttons-Filled-Header-Animated-Preview {
    display: block;
    color: var(--moss);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    transition: all 200ms ease-in-out;;
    text-overflow: ellipsis;
    white-space: nowrap; 
    margin: 0px;
    padding: 0px;
    line-height: fit-content;

    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
}


.Buttons-Filled-HeaderContainer-Animated:hover > * > .Buttons-Filled-Header-Animated-HiddenText {
    display: block;
    height:  -webkit-fit-content;
    height:  -moz-fit-content;
    height:  fit-content;
    color: var(--moss);
    transition: all 100ms ease-in-out;;
}


@media screen and (max-width: 1600px) {
    .Buttons-Unfilled-Header{
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 1089px){
    .FullLengthButton > button{
        width: 100% !important;
    }
    .FullLengthButton > * > button{
        width: 100% !important;
    }
}

.MainBody{
     background-color: var(--papyrus);
}
.GenericLeftRight-Container{
    display: flex;
    padding-bottom: var(--padding-bottom);
    width: 1089px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around !important;
}


.GenericLeftRight-Container-Left{
    padding-left: 65px;
    padding-right: 65px;
    margin-top: auto;
    margin-bottom: auto;
}


.GenericLeftRightButtons {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px
}

.GenericLeftRight-Container-Left > h5 {
    text-transform: uppercase;
    color: #F15621;
    margin-right: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
}

.GenericLeftRight-Container-Left > h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: fit-content;
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: var(--moss);
}

.GenericLeftRight-Container-Left > p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: var(--moss);
    margin: 0;
    padding-bottom: 20px;
    padding-top: 18px;
}

.GenericLeftRight-Container-Right > p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: var(--moss);
    margin: 0;
    padding-bottom: 20px;
    padding-top: 18px;
}

.GenericLeftRight-Container-Left > img {
    width: 467px;
    object-fit: scale-down;
    border-radius: 12px !important;
}

.GenericLeftRight-Container-Right > img {
    width: 467px;
    object-fit: scale-down;
    border-radius: 12px !important;
}


.GenericLeftRight-Container-Left > h1 {
  margin: 0;
  padding: 0;
}

.GenericLeftRight-Container-Right > h1 {
    margin: 0;
    padding: 0;
}



.mobilePangolinNewsHeadline{
  display: none;
}

.GenericLeftRight-MobileLabel{
    display: none;
}

.GenericLeftRight-MobileTitle{
    color: var(--moss);
    display: none;
}

@media screen and (max-width: 1089px) {

    .GenericLeftRight-Container-Left > h1 {
        margin: 0;
        padding-bottom: 24px;
      }
      
      .GenericLeftRight-Container-Right > h1 {
          margin: 0;
          padding-bottom: 24px;
      }
      

    .GenericLeftRight-Container{
        display: flex;
        padding-bottom: var(--padding-bottom-mobile);
    }


    .GenericLeftRight-DesktopTitle{
        display: none;
    }

    .GenericLeftRight-MobileTitle{
        display: block;
    }
    
    .GenericLeftRight-Container{
        flex-direction: column-reverse;
        width: 100vw;
        text-align: center;
    }

    #leftImage{
      flex-direction: column;
    }


    .GenericLeftRight-Container-Left > h5, .GenericLeftRight-Container-Right > h5 {
        display: none;
        padding: 0px;
        margin: 0px;

    }


    .GenericLeftRight-Container-Left{
        padding-left: 16px;
        padding-right: 16px;
    }

    .GenericLeftRight-Container-Right{
        padding-left: 16px;
        padding-right: 16px;
    }

    .GenericLeftRight-Container-Left > img{
        height: 64vw;
        width: auto;
        object-fit: scale-down;
        margin-left: auto;
        margin-right: auto;
    }

    .GenericLeftRight-Container-Right > img{
        height: 64vw;
        width: auto;
        object-fit: scale-down;
        margin-left: auto;
        margin-right: auto;
    }

    .GenericLeftRightButtons{
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-right: 0px;
    }

    .GenericLeftRightButtons-First{
        padding-bottom: 10px;
    }
    
    .GenericLeftRightButtons > div{
        width: 100%;
        padding-right: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    .GenericLeftRight-MobileLabel{
        text-transform: uppercase;
        color: #F15621;
        display: block;
        padding-bottom: 32px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 1px;
        text-align: center;
    }

    .GenericLeftRight-Container-Left > h1, .GenericLeftRight-Container-Right > h1 {
        margin-left: auto;
        margin-right: auto;
        margin: 0px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: fit-content;
        letter-spacing: 0em;
        text-align: center;
    }

    .GenericLeftRight-Container-Left > p, .GenericLeftRight-Container-Right > p {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 32px;
        padding-bottom: 32px;
        margin: 0px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }


    #mobilePangolinNewsHeadline{
      display: block;
    }
}

.MainBody-NumbersContainer{
    background-color: var(--moss);
    color: var(--papyrus);
    margin-top: 24px;
    padding-top: var(--padding-top);
    padding-bottom: calc(var(--padding-bottom) + 4px);
    margin-bottom: calc(var(--padding-bottom));
}

.MainBody-NumbersContainer > h1 {
    padding: 0px;
    margin: 0px;
    color: var(--papyrus);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: fit-content;
    letter-spacing: 0em;
    text-align: center;
}

.MainBody-NumbersContainer-FlexBox {
    width: 100%;
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px
}

.MainBody-NumbersContainer-FlexBox-StatsItem{
    display: flex;
    width: 255px;
    min-height: 186px;
    flex-direction: column;
    border: none; 
    padding-bottom: 16px;
    border-bottom: 2px solid var(--papyrus);
}

.MainBody-NumbersContainer-FlexBox-StatsItem > h1 {
    color: var(--papyrus);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;    
    margin: 0;
    padding: 0;
}

.MainBody-NumbersContainer-FlexBox-StatsItem > p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1089px) {

    .MainBody-NumbersContainer{
        padding-bottom: var(--padding-bottom-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }

    .MainBody-NumbersContainer > h1 {
        padding-left: 16px;
        padding-right: 16px;
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
    }
    
    .MainBody-NumbersContainer-FlexBox-StatsItem {
        border-bottom: none;
    }

    .MainBody-NumbersContainer-FlexBox-StatsItem > h1 {
        text-align: center;
    } 

    .MainBody-NumbersContainer-FlexBox-StatsItem > p{
        text-align: center;
    }

}
.MainBody-TestimonialsContainer{
    background-color: var(--papyrus);
    padding-top: calc(var(--padding-top)/2);
    padding-bottom: var(--padding-bottom);
}

.MainBody-TestimonialsContainer > h1 {
    padding-bottom: 0px;
    margin: 0;
    color: var(--moss);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
}

.MainBody-TestimonialsContainer-Item {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
}

.MainBody-TestimonialsContainer-Item > div {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px
}

.MainBody-TestimonialsContainer-Item > div > h1 {
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 72px;
    font-weight: 800;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
}

.MainBody-TestimonialsContainer-Item > div > p{
    margin-top: 70px;
    color: var(--moss);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}

.MainBody-TestimonialsContainer-Item > h3{
    padding-top: 48px;
    color: var(--moss);
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
}

.MainBody-TestimonialsContainer-Item > h4{
    color: var(--moss);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
}

.MainBody-TestimonialsContainer > .ant-carousel .slick-dots li{
    background-color: #BCB7AA;
    width: 12.5px !important;
    height: 12.5px !important;
    border-radius: 50%
}
  
.MainBody-TestimonialsContainer > .ant-carousel .slick-dots li button{
    background-color: var(--moss);
    width: 12.5px !important;
    height: 12.5px !important;
    border-radius: 50% !important;
}
  
.MainBody-TestimonialsContainer > .ant-carousel .slick-dots li.slick-active button {
    background-color: var(--moss) !important;
    width: 12.5px !important;
    height: 12.5px !important;
    border-radius: 50%
}


.MainBody-TestimonialsContainer-Carousel{
    max-width: 1180px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 350px;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    border-radius: 16px;
}

.MainBody-TestimonialsContainer-Carousel > .slick-dots {
    top: 330px
}

@media screen and (max-width: 1189px) {

    .slick-arrow{
        display: none !important;
    }

    .MainBody-TestimonialsContainer-Item > h3{
        padding-top: 24px;
        padding-bottom: 5px;
    }

    .MainBody-TestimonialsContainer > h1 {
        padding-bottom: 0px;
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
    }

    .MainBody-TestimonialsContainer-Carousel > .slick-dots {
        top: 370px;
    }
}

@media screen and (max-width: 1100px) {
    .MainBody-TestimonialsContainer-Carousel > .slick-dots {
        top: 300px;
    }
}

@media screen and (max-width: 768px) {
    .MainBody-TestimonialsContainer-Carousel > .slick-dots {
        top: 400px;
    }
}

@media screen and (max-width: 530px) {
    .MainBody-TestimonialsContainer-Carousel > .slick-dots {
        top: 500px;
    }
}
.MainBody-Top{
    display: flex;
    flex-direction: column;
    background-color: var(--moss);
    margin-bottom: var(--padding-bottom);
}

.MainBody-Top-Content{
    padding-left: 24px;
    padding-right: 24px;
    padding-top: calc(var(--padding-bottom) - 24px); /* 128px */
    padding-bottom: var(--padding-bottom);
    max-width: 1035px;
    margin-left: auto;
    margin-right: auto;
}

.MainBody-Top-Content > h1 {
    color: var(--papyrus);
    margin: 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: Inter;
    font-size: 72px;
    font-weight: 800;
    line-height: 98px;
    letter-spacing: 0em;
    text-align: center;
}

.MainBody-Top-Content > p {
    padding-top: 40px;
    margin: 0px;
    color: var(--papyrus);
    max-width: 1035px;
    margin-left: auto;
    margin-right: auto;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
}

.MainBody-Top-Content > div {
    margin: 0;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}

.MainBody-Top > img {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    object-fit: cover;
    background-color: var(--papyrus);
}

@media screen and (max-width: 1089px) {

    .MainBody-Top-Content{
        padding-top: var(--padding-bottom-mobile); /* 128px */
        padding-bottom: calc(var(--padding-bottom-mobile) * 2);
    }

    .MainBody-Top{
        margin-bottom: var(--padding-bottom-mobile);
    }

    .MainBody-Top{
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .MainBody-Top-Content{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .MainBody-Top-Content > h1 {
        font-family: Inter;
        font-size: 40px;
        font-weight: 800;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
    }   

    .MainBody-Top-Content > p {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }
    .MainBody-Top-Content > div {
        flex-direction: column;
        grid-row-gap: 12px;
        row-gap: 12px;
    }

    .MainBody-Top > img {
        padding: 0;
        margin: 0;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

}
.WhoWeAre-Body{
    background-color: var(--papyrus);
    margin: 0;
    padding: 0;
}

.WhoWeAre-Body > .TitleSection-body {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 0;

}

.WhoWeAre-Body > .TitleSection-body {
    padding: 0;
}

.WhoWeAre-Body > .TitleSection-body > div {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.WhoWeAre-Body > .TitleSection-body > div > h1 {
    padding-top: 120px;
}

.WhoWeAre-Body > .TitleSection-body > div > p {
    margin-top: 71.5px;
    margin-bottom: 34.5px;
    width: 796px;
    height: 148px;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
}

.WhoWeAre-Body-Team {
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.WhoWeAre-Body-Team-Background{
    background-color: var(--moss);
}

#WhoWeAre-Body-Team-Trustees {
    background-color: var(--moss);
}

#WhoWeAre-Body-Team-Trustees > h1  {
    color: var(--papyrus);
}

#WhoWeAre-Body-Team-Trustees > div > div > h1  {
    color: var(--papyrus);
}


.WhoWeAre-Body-Team > h1{
    color: var(--moss);
    padding-bottom: calc(var(--padding-bottom)/2);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
}

.WhoWeAre-Body-Team-TeamContainer { 
    margin-left: 48px;
    margin-right: 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 48px;
    gap: 48px;
    grid-row-gap: var(--padding-bottom);
    row-gap: var(--padding-bottom);

}
.WhoWeAre-Body-Team-TeamContainer > div {
    max-width: 340px;
    flex: 33% 1;
}

@media screen and (max-width: 1089px) {
    .WhoWeAre-Body-Team-TeamContainer { 
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media screen and (max-width: 780) {

    .WhoWeAre-Body-Team {
        padding-top: var(--padding-top-mobile);

    }

    .WhoWeAre-Body-Team > h1{
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
    }

    .WhoWeAre-Body-Team-TeamContainer { 
        padding-left: 24px;
        padding-right: 24px;
    }

    .WhoWeAre-Body > .TitleSection-body > div > p {
        width: 311px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        padding: 0;
        padding-top: 16px;
        padding-bottom: 32px;
        margin: 0;
    }
}

.TitleSection2-body{
    background-color: var(--moss);
    padding: auto;
    text-align: center;
}

.TitleSection2-body-flex{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

.TitleSection2-body-flex-title{
    margin: 0;
    padding: 0;
    font-weight: 800;
    color: var(--papyrus);
    font-size: 72px;
    text-align: center;
}

.TitleSection2-body-flex-description{
    margin-left: auto;
    margin-right: auto;
    width: 1089px;
    font-weight: 400;
    color: var(--papyrus);
    font-size: 18px;
    text-align: center;
    padding-top: calc(var(--padding-top)/2);
}

@media screen and (max-width: 1089px){
    
    .TitleSection2-body{
        padding-top: 0px;
    }

    .TitleSection2-body-flex{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }

    .TitleSection2-body-flex-title{
        padding-top: 0px;
        font-weight: 800;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        min-width: 311px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
    }

    .TitleSection2-body-flex-description{
        width: 311px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 0px;
        margin: 0px;
        padding-top: calc(var(--padding-top-mobile)/2);
    }
}

.Funders-BackgroundContainer{
    background-color: var(--papyrus);
}

.Funders-Container{
    max-width: 1500px;
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--papyrus);
    color: var(--moss);
    padding-top: calc(var(--padding-top));
    padding-bottom: calc(var(--padding-bottom)*2);
}

.Funders-Container > h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    color: var(--moss);
    padding-bottom: calc(var(--padding-bottom)/2);
}

.Funders-Container > p {
    font-style: normal;
    font-weight: normal;
    width: 487px;
    font-size: 18px;
    line-height: 32px;
    margin: 0;
}

.Funders-Container-ImagesContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    grid-gap: 75px;
    gap: 75px;
    grid-row-gap: 88px;
    row-gap: 88px;
}

.Funders-Container-ImagesContainer > div {
    display: flex;
}

.Funders-Container-ImagesContainer > div > img {
    margin: auto;
}

.Funders-Container-ImagesContainer > div:nth-child(n){
    padding-left: auto;
    padding-right: auto
}


.Funders-Container-ImagesContainer > div >img {
    max-width: 254px;
    max-height: 121px;
}

/* .Funders-Carousel-Container {
    height: 200px;
} */

.Funders-Carousel-Container > img{
    padding-left: 59px;
    padding-right: 59px;
    margin: 0;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    max-height: 300px;
}

.Funders-Container-ImagesContainer-Mobile{
    width: 0;
    height: 0;
    visibility: hidden;
}

.Funders-Container > button {
    margin-left: auto;
    margin-right: auto;
}



@media screen and (max-width: 1089px) {
    .Funders-Container{
        width: 100vw;
        overflow: hidden;
        text-align: center;
        padding-top: 73px;
        padding-bottom: 73px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .Funders-Container > h1 {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: calc(var(--padding-bottom)/2);
    }

    .Funders-Container > p {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }

    .Funders-Container-ImagesContainer{
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        visibility: hidden;
    }

    .Funders-Container-ImagesContainer-Mobile{
        position: relative;
        visibility: visible;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-top: 32px;
    }

    .Funders-Carousel-Container{
        display: flex;
    }

    .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li{
        background-color: #BCB7AA;
        width: 12.5px !important;
        height: 12.5px !important;
        border-radius: 50%
    }
      
    .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li button{
        background-color: var(--moss);
        width: 12.5px !important;
        height: 12.5px !important;
        border-radius: 50% !important;
    }
      
    .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li.slick-active button {
        background-color: var(--moss) !important;
        width: 12.5px !important;
        height: 12.5px !important;
        border-radius: 50%
    }

    .Funders-Carousel-Container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Funders-Carousel-Container > img{
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: a;
        max-height: 100px;
        max-width: 380px;
        margin-bottom: 68px;
    }

    /* .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li{
        background-color: #BCB7AA;

    }
    
    .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li button{
        background-color: #ECE5D1;
    
    } */
    
    /* .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li.slick-active button {
        background-color: #ECE5D1 !important;
        
    } */
}

@media screen and (max-width: 768px) {

    .Funders-Container{
        padding-top: calc(var(--padding-top-mobile));
        padding-bottom: calc(var(--padding-bottom-mobile));
    }
    
    .Funders-Container > h1 {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: calc(var(--padding-bottom-mobile)/2);
        margin: 0px;
    }

    .Funders-Container > p {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }   
}
.WhoWeAre-TeamContainer-Person{
    width: 340px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.WhoWeAre-TeamContainer-Person > div {
    width: 340px;
    height: 440px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.WhoWeAre-TeamContainer-Person > div > img {
    width: 340px;
    height: 440px;
    border-radius: 2px;
    object-fit: cover;
}

.WhoWeAre-TeamContainer-Person > div > div{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: fit-content;
    letter-spacing: 0em;
    text-align: center;
    color: transparent;
    margin-top: auto;
    position: absolute;
    display: flex;
    width: 340px;
    height: 440px;
    border-radius: 2px;
    transition: all 200ms ease-in-out;
}

.WhoWeAre-TeamContainer-Person > div > div > p{
    margin-top: auto;
    margin-bottom: auto;
    padding: 16px;
    visibility: hidden;
}

.WhoWeAre-TeamContainer-Person > div > div:hover{
    visibility: visible;
    color: white;
    background-color: #000000a6; 
    /* Transparency: 65% (a6) */
} 

.WhoWeAre-TeamContainer-Person > div > div:hover > p{
    visibility: visible;
}

.WhoWeAre-TeamContainer-Person > h1 {
    color: var(--moss);
    font-family: Inter;
    font-size: 32px;
    font-weight: 800;
    line-height: fit-content;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 26px;
    margin: 0px;
    width: 100%;
}

.WhoWeAre-TeamContainer-Person > h5 {
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px;
    padding: 0px;
    width: 100%;
}

@media screen and (max-width: 1089px) {

    .WhoWeAre-TeamContainer-Person > h1 {
        font-family: Inter;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
    }

    .WhoWeAre-TeamContainer-Person > h5 {
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
    }

}
.Converter-Body{
    background-color: var(--papyrus);
}
.TitleSectionC-body{
    background-color: var(--moss);
    padding: auto;
    text-align: center;
}

.TitleSectionC-body-flex{
    padding-top: var(--padding-top);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

.TitleSectionC-body-flex-title{
    margin: 0;
    padding: 0;
    width: 1089px;
    font-weight: 800;
    color: var(--papyrus);
    font-size: 72px;
    text-align: center;
}

.TitleSectionC-body-flex-description{
    margin-left: auto;
    margin-right: auto;
    padding-top: calc(var(--padding-top)/2);
    padding-bottom: var(--padding-bottom);
    width: 540px;
    font-weight: 400;
    color: var(--papyrus);
    font-size: 18px;
    text-align: center;
    
}

@media screen and (max-width: 1089px){

    .TitleSectionC-body-flex{
        padding-top: var(--padding-top-mobile);
    }

    .TitleSectionC-body-flex-title{
        font-weight: 800;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        width: 311px;
    }

    .TitleSectionC-body-flex-description{
        width: 311px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        padding-top: calc(var(--padding-top-mobile)/2);
        padding-bottom: var(--padding-bottom-mobile);
    }
}

.IllegalTrade-Container{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--papyrus);
}

.IllegalTrade-Container > h1 {
    color: var(--moss);
    width: 644px;
    margin-right: auto;
    margin-left: auto;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
}

.IllegalTrade-Container > p {
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 996px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
}

.IllegalTrade-Container > p > a{
    color: var(--sunset-orange);
    transition: all 200ms ease-in-out;
}

.IllegalTrade-Container > p > a:hover{
    color: var(--moss-animation);
}

@media screen and (max-width: 1089px) {
    .IllegalTrade-Container{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
        width: 100%;
    }

    .IllegalTrade-Container > h1 {
        color: var(--moss);
        width: 100% !important;
        padding-left: 43.5px;
        padding-right: 43.5px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
    }
    
    .IllegalTrade-Container > p {
        max-width: 100% !important;
        padding-left: 24px;
        padding-right: 24px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;   
    }
    
}
.HowToUseConverter-Container{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--moss);
}

.HowToUseConverter-Container > h1 {
    color: var(--papyrus);
    width: 644px;
    margin-right: auto;
    margin-left: auto;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
}

.HowToUseConverter-Container > p {
    color: var(--papyrus);
    margin-left: auto;
    margin-right: auto;
    width: 542px;
    padding-top: calc(var(--padding-top)/2);
    padding-bottom: calc(var(--padding-bottom)/2);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
}

.HowToUseConverter-Container > div {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 768px) {
    .HowToUseConverter-Container{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }

    .HowToUseConverter-Container > h1 {
        width: 100%;
        padding-left: 45px;
        padding-right: 45px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;        
    }
    
    .HowToUseConverter-Container > p {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 0;
        padding-top: calc(var(--padding-top-mobile)/2);
        padding-bottom: calc(var(--padding-bottom-mobile)/2);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }
    
}
.ScaleConverter-Container{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    margin-left: auto;
    margin-right: auto;
    max-width: 586px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--papyrus);
}

.ScaleConverter-Container > h1 {
    color: var(--moss);
    max-width: 644px;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px;
    padding-bottom: calc(var(--padding-bottom)/2);
}


.ScaleConverter-Container > button {
    margin-left: auto;
    margin-right: auto;
}

.ScaleConverter-Calculator-Desktop{
    display: flex;
    flex-direction: column;
    padding-left: 86px;
    padding-right: 86px;
    padding-top: 37px;
    padding-bottom: 37px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgba(55, 64, 44, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
}

.ScaleConverter-Calculator-Desktop > h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;    
}


.ScaleConverter-Calculator-Desktop-Buttons {
    padding-top: 32px;
    display: flex;
    flex-direction: row;   
}

.ScaleConverter-Calculator-Desktop-Buttons > *{
    margin-right: 12px;
}

.ScaleConverter-Calculator-Desktop-Input{
    display: flex;
    flex-direction: row;   
    width: -webkit-fit-content;   
    width: -moz-fit-content;   
    width: fit-content;
    overflow: hidden;
    background-color: white;
    border-radius: 4px;
}

.ScaleConverter-Calculator-Desktop-Input > input{
    border-style: none;
    width: 196px;
    height: 35px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 4px;
}


.ScaleConverter-Calculator-Desktop-Input-ArrowsButtons{
    display: flex;
    flex-direction: column;
    background-color: var(--moss);
    width: 28px;
    height: 35px;
    
}

.ScaleConverter-Calculator-Desktop-Input-ArrowsButtons > button{
    display: flex;
    background-color: transparent;
    height: 17.5px;
    width: 28px;
    border: 0;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.ScaleConverter-Calculator-Desktop-Input-ArrowsButtons > button:hover{
    background-color: var(--moss-animation);
}

.ScaleConverter-Calculator-Desktop-Input-ArrowsButtons > button > img{
    margin: auto;
}

.ScaleConverter-Calculator-Desktop-Inputs{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
}

.ScaleConverter-Calculator-Desktop-Inputs > input{
    border-style: none;
    margin-right: 24px;
    width: 196px;
    height: 50px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    -webkit-appearance: none;
    border-radius: 4px;
}

.ScaleConverter-Calculator-Desktop-Inputs > div{
    border-radius: 4px;
    border: 1px solid var(--moss);
    overflow: visible;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    background-color: var(-moss);
}

.ScaleConverter-Calculator-Desktop-Inputs > div > button{
    width: 50px;
    height: 48px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    border: none;
    transition: all 200ms ease-in-out;
}

.ScaleConverter-Calculator-Desktop-Inputs > div > .ScaleConverter-Calculator-Desktop-Inputs-gButton{
    color: var(--moss);
    background-color: var(--papyrus);
    margin: 0;
    padding: 0;
}

.ScaleConverter-Calculator-Desktop-Inputs > div > .ScaleConverter-Calculator-Desktop-Inputs-kgButton{
    color: var(--papyrus);
    background-color: var(--moss);
    margin: 0;
    padding: 0;
}

.ScaleConverter-Calculator-Desktop-Inputs > div > .ScaleConverter-Calculator-Desktop-Inputs-kgButton:hover{
    background-color: var(--moss-animation);
}

.ScaleConverter-Calculator-Desktop-Inputs > div > .ScaleConverter-Calculator-Desktop-Inputs-gButton:hover{
    background-color: var(--papyrus-animation);
}


.ScaleConverter-Calculator-Desktop-Inputs > input:focus{
   outline: 1px solid var(--moss);
   border-radius: 4px
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ScaleConverter-Container-Second{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--papyrus);
}

.ScaleConverter-Container-Second > div > h1 {
    color: var(--moss);
    max-width: 100%;
    padding-bottom: 0 !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 8px;    
}

.ScaleConverter-Container-Second > div > p {
    color: var(--moss);
    margin-left: auto;
    margin-right: auto;
    max-width: 730px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;

}


.ScaleConverter-Results {
    margin-left: auto;
    margin-right: auto;
}

.ScaleConverter-Results-RowHeaders {
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: right;
    padding-bottom: 24px;
    padding-right: 24px;
} 

.ScaleConverter-Results-ColumnHeaders {
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    min-height: 96px;
    padding-left: 24px;
    padding-right: 24px;
}

.ScaleConverter-Results > tr > svg {
    width: 22px !important;
    padding-left: 10px;
    padding-right: 10px;
}

.ScaleConverter-Results > tr > td {
    margin: 0;
    min-width: 255px;
    min-height: 96px;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 24px;
}

/* .ScaleConverter-Results{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 730px;
}
.ScaleConverter-Results > div {
    flex: 1 1 33%;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
}

.ScaleConverter-Results > div > h1 {
    margin: 0;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
}

.ScaleConverter-Results > div > h5 {
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
}

.ScaleConverter-Results-Columns{
    height: fit-content !important;
    margin-bottom: auto !important;
    font-weight: 600 !important;
}

.ScaleConverter-Results > div > span {
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
} */

@media screen and (max-width: 1089px) {
    .ScaleConverter-Container{
        margin-top: 0px !important;
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
        margin-left: auto;
        margin-right: auto;
    }

    .ScaleConverter-Container-Second{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }
    
    .ScaleConverter-Results-RowHeaders {
        font-size: 18px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: right;
        padding-bottom: 24px;
        padding-right: 24px;
    } 
    
    .ScaleConverter-Results-ColumnHeaders {
        font-size: 18px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        min-height: 96px;
        padding-left: 24px;
        padding-right: 24px;
    }
    
    .ScaleConverter-Results > tr > td {
        margin: 0;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        min-height: 96px;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 64px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 24px;
    }
    
}

@media screen and (max-width: 768px) {
    .ScaleConverter-Container{
        margin-top: var(--padding-top-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }

    .ScaleConverter-Container > h1 {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 24px;
        padding-right: 24px;  
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;  
        padding-bottom: calc(var(--padding-bottom-mobile)/2);
    }
    
    .ScaleConverter-Container > p {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 32px;
        padding-bottom: 44px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }

    .ScaleConverter-Calculator-Desktop{
        border: none;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 24px;
        padding-right: 24px;
        margin-left: auto;
        margin-right: auto;
    }

    .ScaleConverter-Calculator-Desktop   > h5 {
        margin: 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding-bottom: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
    }
    
    .ScaleConverter-Calculator-Desktop-Buttons{
        flex-direction: column;
        grid-gap: 6px;
        gap: 6px;
    }

    .ScaleConverter-Calculator-Desktop-Buttons > * > button{
        width: 190px;
        height: 48px;
    }

    .ScaleConverter-Calculator-Desktop-Inputs{
        width: 100%;
        justify-content: space-around;
    }

    .ScaleConverter-Calculator-Desktop-ValueInput-Measure{
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
    }
    .ScaleConverter-Calculator-Desktop-Inputs > input{
        width: 100%;
    }

    .ScaleConverter-Calculator-Desktop-Buttons > *{
        margin-right: 0px;
    }


    .ScaleConverter-Calculator-Desktop > div > div:nth-of-type(2) > button{
        padding-bottom: 0;
        margin-bottom: 0;
        
    }

    .ScaleConverter-Container{
        margin-bottom: 0;
    }

    .ScaleConverter-Container-Second{
        width: 100vw;
    }

    .ScaleConverter-Container-Second > div > h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 8px;
    }
    
    .ScaleConverter-Container-Second > div > p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 32px;
    }

    .ScaleConverter-Results > tr > svg {
        width: 22px !important;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 24px;
    }

    .ScaleConverter-Results {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .ScaleConverter-Results-RowHeaders {
        font-size: 12px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: right;
        padding-bottom: 12px;
        padding-right: 12px;
    } 
    
    .ScaleConverter-Results-ColumnHeaders {
        font-size: 12px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        min-height: 96px;
        padding-left: 24px;
        padding-right: 24px;
    }
    
    .ScaleConverter-Results > tr > td {
        margin: 0;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        min-height: 96px;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 24px;
    }

}

@media screen and (max-width: 400px) {

    .ScaleConverter-Results-RowHeaders {
        font-size: 10px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: right;
        padding-bottom: 12px;
        padding-right: 12px;
    } 
    
    .ScaleConverter-Results-ColumnHeaders {
        font-size: 10px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        min-height: 96px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .ScaleConverter-Results > tr > td {
        margin: 0;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        min-height: 96px;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 24px;
    }


}
.Instructions-Container{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    max-width: 978px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: center;
    background-color: var(--papyrus);
}

.Instructions-Container > h1 {
    color: var(--moss);
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-align: left;
    padding-bottom: calc(var(--padding-top)/2);
}

.Instructions-Container > ol{
    padding-left: 24px;
}


.Instructions-Container > ol > li {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .Instructions-Container{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }

    .Instructions-Container > h1 {
        width: 100%;
        padding-left: 82px;
        padding-right: 82px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: calc(var(--padding-top-mobile)/2);
    }

    .Instructions-Container > ol > li {
        margin-left: 24px;
        margin-right: 24px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
    }

}

.Blog-Body-iFrame {
    width: 100%;
    height: 100vh;
    background-color: transparent;
    background-image: url(/static/media/Spinner.fa987a8c.gif);  
    background-size: 100px; 
    background-position: center; 
    background-repeat: no-repeat;
}

.Blog {
    width: 100%;
    background-color: var(--papyrus);
}

.Blog-Body-Spinner {
    position: absolute;
    width: 100%;
}

.Blog-Body-Pagination {
    display: flex;
    width: 100%;
}

.Blog-Body-NoItems{
    min-height: 340px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-left: auto;
    margin-right: auto;
}

.Blog-Body-NoItems-FirstLine{
    color: var(--moss);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: calc(var(--padding-top)*2);
}

.Blog-Body-NoItems-SecondLine{
    color: var(--moss);
    font-family: Inter;
    font-size: 40px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: calc(var(--padding-bottom)*2 + 32px);
}

.Blog-Body-TypeSelctionContainer {
    display: flex;
    max-width: 1206px;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    margin-bottom: 32px;
    grid-gap: 24px;
    gap: 24px;
    padding-left: 24px;
    padding-right: 24px;
    flex-wrap: wrap;
}

.Blog-Body-TypeSelctionContainer-Item {
    background-color: transparent;
    color: var(--moss);
    border: 1px solid #37402C;
    border-radius: 25px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.Blog-Body-TypeSelctionContainer-Item-Selected {
    background-color: var(--moss);
    color: var(--papyrus);
    border: 1px solid #37402C;
    border-radius: 25px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.Blog-Drawer > * > * > * > * > * > div{
    color: var(--moss) !important;
    background-color: var(--papyrus);
}

@media screen and (max-width: 1080px) {

    .Blog-Body-NoItems{
        grid-gap: 16px;
        gap: 16px;
    }
    
    .Blog-Body-NoItems-FirstLine{
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 24px;
        padding-right: 24px;
    }
    .Blog-Body-NoItems-SecondLine{
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 24px;
        padding-right: 24px;
    }
}
    


.Blog-Container {
    max-width: 1062px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 45px;
    padding-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
}

.Blog-Container > span{
    margin: 0;
    padding: 0;
}


.Blog-Container-Date {
    color: rgba(55, 64, 44, 0.6);
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
    padding: 0;
    margin: 0;
}

.Blog-Container-Title {
    color: var(--moss);
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    margin-top: 8px;
    margin-bottom: 8px;
}

.Blog-Container-TypeContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    border-radius: 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--sunset-orange);
}

.Blog-Container-Type {
    color: #ECE5D1;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
    margin: 0;
}

.Blog-Container-Description {
    color: var(--moss);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    margin-top: 32px;
    margin-bottom: 32px;
}
.Generic-Pagination-Container{
    margin-left: auto;
    margin-right: auto;    
    margin-top: var(--padding-top);
    margin-bottom: var(--padding-bottom);
}

.Generic-Pagination-Container > * {
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer !important;
}

.Generic-Pagination-Container > a > img {
    margin-bottom: 3px;
    cursor: pointer !important;
}

@media screen and (max-width: 1080px) {
    .Generic-Pagination-Container{
        margin-top: var(--padding-top-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }
}
    
.markdown-styles_reactMarkDown__Hos4n{
    font-family: 'Inter', sans-serif;
    color: var(--moss) !important;
}


.markdown-styles_reactMarkDown__Hos4n > * {
    font-family: Inter !important;
    color: var(--moss) !important;
}


.markdown-styles_reactMarkDown__Hos4n h1 {
    color: var(--moss);
    font-family: Inter;
    font-size: 72px;
    font-weight: 800;
    line-height: 98px;
    letter-spacing: 0em;
}

.markdown-styles_reactMarkDown__Hos4n h2 {
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
}

.markdown-styles_reactMarkDown__Hos4n h3 {
    font-family: Inter;
    font-size: 40px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
}

.markdown-styles_reactMarkDown__Hos4n h4 {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
}

.markdown-styles_reactMarkDown__Hos4n h5 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
}

.markdown-styles_reactMarkDown__Hos4n code {
    font-family: Inter;
    color: var(--moss-animation);

}

.markdown-styles_reactMarkDown__Hos4n a {
    color: var(--sunset-orange);
}

.markdown-styles_reactMarkDown__Hos4n a:hover {
    color: var(--moss-animation);
}
.MarkdownPage-MainContainer{
    display: flex;
    background-color: var(--papyrus) !important;
}

.MarkdownPage{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-top);
    background-color: var(--papyrus);
    max-width: 900px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: var(--papyrus);
    margin-left: auto;
    margin-right: auto;
}

.MarkdownPage h1{
    color: var(--moss);
    font-family: Inter;
    font-size: 72px;
    font-weight: 800;
    line-height: 98px;
    letter-spacing: 0em;
    text-align: center;
    background-color: var(--papyrus);    
    margin: 0;
}

.MarkdownPage h3 {
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    background-color: var(--papyrus);
    margin: 0;
    padding-top: 16px;
    padding-bottom: 40px;
}

.MarkdownPage h3 > span {
    margin: 0;
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    background-color: var(--papyrus);
}

.MarkdownPage img {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}

.MarkdownPage > .MdContainer {
    padding-top: 24px;
    padding-bottom: 124px;
    border-radius: 2px;
}

.MarkdownPage > .AuthorContainer {
       top: -100px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: var(--moss);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
    box-sizing: border-box;
}

.MarkdownPage > .AuthorContainer > img {
    position: relative;
    top: -100px;
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
}

.MarkdownPage > .AuthorContainer > h1 {
    position: relative;
    top: -100px;
    display: block;
    color: var(--papyrus);
    padding-top: -100px;
    font-family: Inter;
    font-size: 40px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
}

.MarkdownPage > .AuthorContainer > h3 {
    position: relative;
    top: -100px;
    display: block;
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
}

.MarkdownPage > .AuthorContainer > p {
    position: relative;
    display: block;
    color: var(--papyrus);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
    overflow: visible;
    margin-top: -100px;
    padding-bottom: 26px;
}

@media screen and (max-width: 1080px) {
    .MarkdownPage{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-top-mobile);
        max-width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }
}
.ContactUs-Body{
    background-color: var(--papyrus);
}

.ContactUs-Body-Form{
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    margin: 0 auto;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    padding-left: 16px;
    padding-right: 16px;
    grid-gap: 18px;
    gap: 18px;
}

.ContactUs-Body-Form > h1 {
    margin: 0px;
    color: var(--moss);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;
}

.ContactUs-Body-Form > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    grid-gap: 42px;
    gap: 42px;
}

.ContactUs-Body-Form > div > *{
    width: 100%;
}

.ContactUs-Body-Form-Field {
    min-height: 60px;
    width: 100%;
    border: 3px solid rgba(205, 205, 205, 1);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding: 8px;
}

.ContactUs-Body-Form-LargeField {
    min-height: 289px;
    width: 100%;
    border: 3px solid rgba(205, 205, 205, 1);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding: 8px;
}

.ContactUs-Body-Form-Field:focus, .ContactUs-Body-Form-LargeField:focus {
    outline: 2px solid var(--moss);
    border-radius: 1px;
  }

.ContactUs-Body-Form-Label {
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    /* since the gap is 18px, but we need 16px padding: */
    padding-bottom: -2px;

} 

.ContactUs-Body-Form-MicroLabel {
    color: var(--moss);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 8px;
}

.ContactUs-Body-Form > button {
    /* since the gap is 18px, but we need 32px margin/padding: */
    margin-top: 14px;
}

.ContactUs-Body-Form-SuccessMessage {
    padding-left: 18px;
    font-size: 14px;
    color: var(--moss-animation);
}

.ContactUs-Body-Form-ErrorMessage{
    padding-left: 18px;
    font-size: 14px;
    color: var(--sunset-orange);
}

@media screen and (max-width: 1100px) {

    .ContactUs-Body-Form{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }
    
    .ContactUs-Body-Form > h1 {
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;   
        /* since the gap is 18px, but we need 32px margin/padding: */
        margin-bottom: 32px;
    }

    .ContactUs-Body-Form > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        grid-gap: 42px;
        gap: 42px;
    }
}

.Footer{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    background-color: var(--moss);
}

/* DESKTOP */
/* MAIN CONTAINER */

.FooterD{
    background-color: var(--moss);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding-left: 110px;
    padding-right: 110px;
}

.FooterD-GroupOf-Cards{
    display: flex;
    flex-direction: row;
}

.FooterD-Image{
    display: none;
    height: 459px;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.FooterD-MainContainer{
    padding-top: 48px; /* 96px */
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 24px;
    grid-gap: 0px;
    gap: 0px
}

#FooterD-Card-Main{
    width: 255px;
    margin-right: auto;
}


.FooterD-Card{
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px;
    width: 230px;
}

.FooterD-Card > img{
    width: 116.35px;
    height: auto;
    padding-bottom: 12px;
    padding-left: auto;
}

.FooterD-Card > div > h1{
    color: white;
    margin: 0px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 12px;
}

.FooterD-Card > p {
    max-width: 100%;
    color: var(--papyrus);
    margin: 0px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.FooterD-Card-Item {
    max-width: 180px;
}

.FooterD-Card-Item > span {
    padding-top: 0px;
    color: var(--papyrus);
    margin: 0px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    transition: all 200ms ease-in-out;
}

.FooterD-Card-Item > span:hover{
    color: var(--papyrus-animation) !important;
}

.FooterD-Card-SocialMedia {
    display: flex;
    flex-direction: row;
    grid-gap: 16px;
    gap: 16px;
}

.FooterD-Card-SocialMedia > a > img{
    width: 24px;
    height: 24px;
}

/* SECOND CONTAINER */

.FooterD-SecondContainer{
    width: 100%;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 26.85px;
    padding-bottom: 22.15px;
}

.FooterD-SecondContainer-Left{
    display: flex;
    flex-direction: row;
    grid-gap: 8px;
    gap: 8px;
}

.FooterD-SecondContainer-Left > h1{
    color: var(--papyrus);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.FooterD-SecondContainer-Right{
    display: flex;
    flex-direction: row;
    grid-gap: 8px;
    gap: 8px;
}

.FooterD-SecondContainer-Item > a, .FooterD-SecondContainer-Item {
    color: var(--papyrus);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    transition: all 200ms ease-in-out;
}

.FooterD-SecondContainer-Item > a:hover{
    color: var(--papyrus-animation) !important;
    
}

@media screen and (max-width: 1300px) {
    .FooterD-Card{
        width: 200px;
    } 
}

@media screen and (max-width: 1150px) {
    .FooterD-Card{
        width: 150px;
    } 
}

@media screen and (max-width: 1000px) {

    .FooterD{
        padding-left: 31px;
        padding-right: 31px;

    }

    .FooterD-MainContainer{
        flex-direction: column;
        margin-bottom: 0px;
    }

    .FooterD-Image{
        display: none;
    }

    
    .FooterD-GroupOf-Cards{
        grid-gap: 24px;
        gap: 24px;
        grid-row-gap: 24px;
        row-gap: 24px;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 24px !important;
        margin-bottom: 12px !important;
        justify-content: left;
    }

    .FooterD-Card{
        width: -webkit-max-content;
        width: max-content;
    }


    .FooterD-Card:first-child{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .FooterD-SecondContainer{
        flex-direction: column;
        padding-top: 0px;
        padding-bottom: 0px;
        justify-content: left;
    }
   
    .FooterD-Card{
        padding-bottom: 0px;
        padding: 0px !important;
        margin: 0px !important;
    }

    .FooterD-SecondContainer-Left{
        flex-direction: row;
        grid-gap: 6px ;
        gap: 6px ;
        flex-wrap: wrap;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .FooterD-SecondContainer-Left > h1{
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

    .FooterD-Card-SocialMedia{
        padding-bottom: 24px;
    }
}

@media screen and (max-width: 768px) {
  
    .FooterD-GroupOf-Cards{
        justify-content: left;
    }
}
.Awareness-Description-Container{
    margin-top: var(--padding-top);
    margin-bottom: var(--padding-bottom);
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    background-color: var(--papyrus);
    color: var(--moss);
}

.Awareness-Description-Container > h1 {
    color: var(--moss);
    max-width: 644px;
    margin-left: auto;
    margin-right: auto;
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
    
}
.Awareness-Description-Container > p {
    max-width: 996px;
    margin-left: auto;
    margin-right: auto;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
}

@media screen and (max-width: 1080px) {
    .Awareness-Description-Container{
        margin-top: var(--padding-top-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }

    .Awareness-Description-Container > h1 {
        max-width: 100%;
        padding-left: 47px;
        padding-right: 47px;
        font-family: Inter;
        font-size: 28px;
        font-weight: 800;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;   
    }

    .Awareness-Description-Container > p {
        margin-top: 16px;
        max-width: 100%;;
        padding-left: 24px;
        padding-right: 24px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }
    
}

.Awareness-Body{
    background-color: var(--papyrus);
}
.PangolinsFactsMain-Body{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    margin-left: auto;
    margin-right: auto;
    width: 1062px;
    background-color: var(--papyrus);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.PangolinsFactsMain-Body > img {
    border-radius: 12px;
    max-width: 540px;
    max-height: 540px;
}

.PangolinsFactsMain-Body > div {
    display: flex;
    flex-direction: column;
}

.PangolinsFactsMain-Body > div > h1 {
    font-family: Inter;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: left;
    color: var(--moss);
    margin-bottom: 0px;
}

.PangolinsFactsMain-Body-DesktopHeader{
    padding: 0px;
    margin: 0px;
}

.PangolinsFactsMain-Body > div > p {
    padding-top: calc(var(--padding-top)/2);
    margin-top: 0px;
    width: 444px;
    left: 48px;
    font-size: 18px;
    border-radius: nullpx;
}

.PangolinsFactsMain-Body-MobileHeader{
    margin: 0px;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    display: none;
}

@media screen and (max-width: 1080px) {

    .PangolinsFactsMain-Body{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding-top: var(--padding-top-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }

    .PangolinsFactsMain-Body-DesktopHeader{
        display: none;
    }


    .PangolinsFactsMain-Body-MobileHeader{
        display: block;
        margin: 0px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 8px;
    }
    
    .PangolinsFactsMain-Body{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding-bottom: var(--padding-bottom-mob);
    }

    .PangolinsFactsMain-Body > img {
        border-radius: 12px;
        height: 64vw;
        width: auto;
    }
    .PangolinsFactsMain-Body > div > p {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        max-width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 24.5px;
        margin-bottom: 0px;
        text-align: center
    } 
    
    
}
.PangolinsFactsCarousel-Body{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: var(--padding-bottom);
}

.PangolinsFactsCarousel-Body > h1 {
    font-family: Inter;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: center;
    color: var(--moss);
    padding: 0px;
    margin: 0px;
}

.PangolinsFactsCarousel-Body > p {
    margin-left: auto;
    margin-right: auto;
    max-width: 898px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: calc(var(--padding-top)/2);
    margin-bottom: calc(var(--padding-bottom)/2);
}

.PangolinsFactsCarousel-Body-Carousel{
    width: 946px;
    height: 602px;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    border-radius: 12px;
}


.PangolinsFactsCarousel-Body-Carousel-Item {
    border-radius: 12px;
}

.PangolinsFactsCarousel-Body-Carousel-Item > div {
    border-radius: 12px 12px 0px 0px;
}

.PangolinsFactsCarousel-Body-Carousel-Item {
    padding-left: 24px;
    padding-right: 24px;
    width: 946px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
}

.PangolinsFactsCarousel-Body-Carousel > .slick-list > .slick-track > :nth-child(n) > div > div > div  {
    display: block;
    width: 100%;
    background-color: #F9B9B3;
}

.PangolinsFactsCarousel-Body-Carousel > .slick-list > .slick-track > :nth-child(2n) > div > div > div  {
    display: block;
    background-color: #F3D956;
}

.PangolinsFactsCarousel-Body-Carousel > .slick-dots {
    top: 339px;
}

.PangolinsFactsCarousel-Body-Carousel-Item > div > img {
    max-width: 100%;
    padding: 5%;
    max-height: 354px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-right: auto;
    margin-left: auto;
    border-radius: 12px;
    overflow: hidden !important;
}

.PangolinsFactsCarousel-Body-Carousel-Item > span {
    display: flex;
    background-color: white;
    border-radius: 0px 0px 12px 12px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 72px;
    padding-right: 72px;
}

.PangolinsFactsCarousel-Body-Carousel-Item > span > p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

@media screen and (max-width: 1080px) {

    .PangolinsFactsCarousel-Body{
        padding-right: 24px;
        padding-left: 24px;
        padding-bottom: var(--padding-bottom-mobile);
        padding-top: var(--padding-top-mobile);
    }

    .PangolinsFactsCarousel-Body > h1 {
        margin: 0px;
        padding: 0px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 4px;
    }

    .PangolinsFactsCarousel-Body > p {
        margin-top: 0px;
        padding-top: 0px;
    }
    
    .PangolinsFactsCarousel-Body-Carousel{
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .PangolinsFactsCarousel-Body-Carousel-Item {
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .PangolinsFactsCarousel-Body-Carousel-Item > span {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-left: 0px;
        padding-right: 0px;
    }

    .PangolinsFactsCarousel-Body-Carousel-Item > span > p {
        padding-top: 34px;
        padding-bottom: 34px;
        padding-left: 10px;
        padding-right: 10px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}
.PangolinFacts-Body{
    background-color: var(--papyrus);
}
.Art-Container-Card{
    width: 1200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.Art-Container-Card > div {
    margin-top: var(--padding-top);
    width: 350px;
    min-height: 504px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    outline: 1px solid rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
    border-radius: 2px;
}


.Art-Container-Card > div > div > img {
    width: 350px;
    height: 350px;
    left: -2px;
    object-fit: cover;
    border-radius: 2px  2px  0px 0px;
}

.ant-image-mask{
    border-radius: 2px  2px  0px 0px !important;
}

.Art-Container-Card-Author{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: #37402C;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
}

.Art-Container-Card-Text{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: #37402C;
    padding-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
}

.Art-Container-Card-Country{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;    
    color: rgba(0, 0, 0, 0.45);
    padding-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
}


/* style = {{ , padding:'40px', width: '300px' }} */
/* @media :-moz-full-screen */
@media screen and (max-width: 1190px) {
    
    .Art-Container-Card{
        width: 95vw;
    }

    .Art-Container-Card > div {
        margin: calc(var(--padding-top-mobile));
    }
}

    
.ArtGallery-Title-body{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--moss);
    padding: auto;
}

.ArtGallery-Title-body-flex{
    padding-top: var(--padding-top);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

.ArtGallery-Title-body-flex-title{
    margin: 0;
    padding: 0;
    font-weight: 800;
    color: var(--papyrus);
    font-size: 72px;
    text-align: center;
}

.ArtGallery-Title-body-flex-description{
    margin: 0;
    margin-top: calc(var(--padding-top)/2);
    padding: 0;
    width: 613px;
    font-weight: 400;
    color: var(--papyrus);
    font-size: 18px;
    text-align: center;
}

.ArtGallery-Title-body-flex-button{
    margin: auto;
    margin-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
}

@media screen and (max-width: 1089px){

    .ArtGallery-Title-body-flex{
        padding-top: var(--padding-top-mobile);
    }

    .ArtGallery-Title-body-flex-title{
        font-size: 40px;
        font-weight: 800;
        line-height: 54px;
        letter-spacing: 0em;
    }

    .ArtGallery-Title-body-flex-description{
        margin-top: calc(var(--padding-top)/2);      
        width: 311px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        text-align: center;
    }

    .ArtGallery-Title-body-flex-button{
        margin: auto;
        margin-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }
}

.Art-Gallery-Container{
    display: flex;
    flex-direction: column;
    background-color: var(--papyrus);
}

.Art-Gallery-Buttons-Container{
    margin-left: auto;
    margin-right: auto;    
    margin-top: var(--padding-top);
    margin-bottom: var(--padding-bottom);
}

.Art-Gallery-Buttons-Container > * {
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer !important;
}

.Art-Gallery-Buttons-Container > a > img {
    margin-bottom: 3px;
    cursor: pointer !important;
}

@media screen and (max-width: 1089px) {
    .Art-Gallery-Buttons-Container{  
        margin-top: var(--padding-top-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }


}
.Shop-Body{
    background-color: var(--papyrus);
}

.Shop-Body > div {
    padding-top: var(--padding-top);
}

@media screen and (max-width: 1089px) {
    .Shop-Body > div {
        padding-top: var(--padding-top-mobile);
    }
}
.CommunityConservation-Body{
    background-color: var(--papyrus);
    margin: 0;
    padding: 0;
    min-height: calc(100vh - 94px - 300px - 224.4px);
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.CommunityConservation-Body > div{
    height: -webkit-max-content;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
}

.CommunityConservation-Description{
    width: 768px;
    height: 100%;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    margin: auto;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
}

@media screen and (max-width: 1089px) {
    .CommunityConservation-Description{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-left: 24px;
        padding-right: 24px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }
}
.PangoCard-Container{
    display: flex;
    flex-direction: column;
    width: 543px;
    min-height: 614px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: transparent;
    border-radius: 12px;
    border: 1px #37402C4D solid;
    transition: all 200ms ease-in-out
}

.PangoCard-Container-SELECTED{
    border: 1px solid var(--moss-animation);
    background-color: var(--moss);
    color: var(--papyrus);
}

.PangoCard-Container-SELECTED > h1 {
    color: var(--papyrus);
}

.PangoCard-Container > h1 {
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    padding-top: 16px;
    padding-bottom: 68px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
}

.PangoCard-Container > img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.PangoCard-Container > div {
    margin-left: auto;
    margin-right: auto;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.PangoCard-Container:hover{
    box-shadow: 0px  10px 20px 0px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1150px){
    .PangoCard-Container{
        width: calc(100% - 24px - 24px);
        margin-left: 24px;
        margin-right: 24px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
    }

    .PangoCard-Container > img {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-width: 100%;
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}
.WhichPango-Container{
    background-color: var(--papyrus);
}

.WhichPango-Container-BtnContainer{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-top: calc(var(--padding-top)/2);
    padding-bottom: calc(var(--padding-bottom));
}

.WhichPango-Cards {
    margin-left: auto;
    margin-right: auto;
    width: 1134px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: calc(var(--padding-top));
}

.WhichPango-Cards > * {
    cursor: pointer;
    margin: 12px;
}

.WhichPango-Results{
    padding-top: calc(var(--padding-top));
    padding-bottom: calc(var(--padding-bottom));
    margin-left: auto;
    margin-right: auto;
    max-width: 1134px;
}

.WhichPango-Results > h1{
    text-align: center;
    font-family: Inter;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
}

.WhichPango-Results-Item {
    margin-left: auto;
    margin-right: auto;
}

.WhichPango-Share {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 16px;
    gap: 16px;
    padding-bottom: calc(var(--padding-bottom));
}

.WhichPango-Share > * {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.WhichPango-Share > h1 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;    
}

.WhichPango-Share > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 37.81px;
    gap: 37.81px;
}

.WhichPango-Share-Image{
    cursor: pointer;
    height: 25px;
}

@media screen and (max-width: 1150px){
    .WhichPango-Cards {
        width: 100%;
        flex-direction: column;
        margin-top: calc(var(--padding-top));
    }

    .WhichPango-Cards > * {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .WhichPango-Container-BtnContainer{
        padding-top: calc(var(--padding-top-mobile));
        padding-bottom: calc(var(--padding-bottom-mobile)*2);
    }

    .WhichPango-Results{
        padding-top: calc(var(--padding-top-mobile));
        padding-bottom: calc(var(--padding-bottom-mobile));
        padding-left: 24px;
        padding-right: 24px;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }

    .WhichPango-Share {
        padding-bottom: calc(var(--padding-bottom-mobile)*2);
    }
}


.Page404{
    background-color: var(--papyrus);
    width: 100%;
    min-height: calc(100vh - 93px - 471px);
    padding-bottom: calc(var(--padding-bottom)*3);
}

.Page404-Body{
    background-color: var(--papyrus);
    padding-top: calc(var(--padding-top)*3);
    width: 826px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    grid-gap: calc(var(--padding-top)/1.5);
    gap: calc(var(--padding-top)/1.5)
}

.Page404-Body > h1 {
    margin: 0;
    padding: 0;
    font-family: Inter;
    font-size: 128px;
    font-weight: 800;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: center;    
}

.Page404-Body > p {
    margin: 0;
    padding: 0;
    font-family: Inter;
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;    
}

.Page404-Body > div {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 768px) {
    .Page404-Body{
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }
    .Page404-Body > h1 {
        font-family: Inter;
        font-size: 96px;
        font-weight: 800;
        line-height: 80px;
        letter-spacing: 0em;
        text-align: center;
    }
    .Page404-Body > p {
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }
}
    

.Sitemap{
    background-color: var(--papyrus);
    min-height: calc(100vh - 94px - 330px);
}

.Sitemap-Body{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
}

.Sitemap-Title{
    text-transform: lowercase;
}

.Sitemap-Title > li {
    font-size: 24px;
}

.Sitemap-Title > li > span {
    color: var(--moss);
}

.Sitemap-Title > li >  * {
    color: var(--moss-animation);
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize;
}

.Sitemap-Title > li > a:hover{
    color: var(--moss);
}

.Sitemap-Body{
    padding-left: 24px;
    padding-right: 24px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 540px;
    margin-left: auto;
    margin-right: auto;
}

.Sitemap-Item > li {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    list-style: circle;
    margin-left: 32px;
}

.Sitemap-Item > li > * {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: var(--moss-animation);
    padding: 0;
    margin: 0;
}

.Sitemap-Item:hover > li > *{
    color: var(--moss);
}

@media screen and (max-width: 768px) {
    .Sitemap-Body{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }
}
    
.Blog-Body-iFrame {
    width: 100%;
    height: 100vh;
    background-color: transparent;
    background-image: url(/static/media/Spinner.fa987a8c.gif);  
    background-size: 100px; 
    background-position: center; 
    background-repeat: no-repeat;
}

.Blog {
    width: 100%;
    background-color: var(--papyrus);
}

.Blog-Body-Spinner {
    position: absolute;
    width: 100%;
}

.Blog-Body-Pagination {
    display: flex;
    width: 100%;
}

.Blog-Body-NoItems{
    min-height: 340px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-left: auto;
    margin-right: auto;
}

.Blog-Body-NoItems-FirstLine{
    color: var(--moss);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: calc(var(--padding-top)*2);
}

.Blog-Body-NoItems-SecondLine{
    color: var(--moss);
    font-family: Inter;
    font-size: 40px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: calc(var(--padding-bottom)*2 + 32px);
}

.Blog-Body-TypeSelctionContainer {
    display: flex;
    max-width: 1206px;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    margin-bottom: 32px;
    grid-gap: 24px;
    gap: 24px;
    padding-left: 24px;
    padding-right: 24px;
    flex-wrap: wrap;
}

.Blog-Body-TypeSelctionContainer-Item {
    background-color: transparent;
    color: var(--moss);
    border: 1px solid #37402C;
    border-radius: 25px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.Blog-Body-TypeSelctionContainer-Item-Selected {
    background-color: var(--moss);
    color: var(--papyrus);
    border: 1px solid #37402C;
    border-radius: 25px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.Blog-Drawer > * > * > * > * > * > div{
    color: var(--moss) !important;
    background-color: var(--papyrus);
}

@media screen and (max-width: 1080px) {

    .Blog-Body-NoItems{
        grid-gap: 16px;
        gap: 16px;
    }
    
    .Blog-Body-NoItems-FirstLine{
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 24px;
        padding-right: 24px;
    }
    .Blog-Body-NoItems-SecondLine{
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 24px;
        padding-right: 24px;
    }
}
    


.Career-Container {
    max-width: 1062px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 45px;
    padding-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    border: 1px #37402C4D solid;
    transition: all 200ms ease-in-out;
}

.Career-Container:hover{
    box-shadow: 0px  10px 20px 0px rgba(0, 0, 0, 0.25);

}

.Career-Container > span{
    margin: 0;
    padding: 0;
}


.Career-Container-Date {
    color: rgba(55, 64, 44, 0.6);
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
    padding: 0;
    margin: 0;
}

.Career-Container-Title {
    color: var(--moss);
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    margin-top: 8px;
    margin-bottom: 8px;
}

.Career-Container-TypeContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    border-radius: 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--sunset-orange);
}

.Career-Container-Type {
    color: #ECE5D1;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
    margin: 0;
}

.Career-Container-Description {
    color: var(--moss);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    margin-top: 32px;
    margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
    .Career-Container {
        margin-left: 16px;
        margin-right: 16px;
    }    
}
