.HowToUseConverter-Container{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--moss);
}

.HowToUseConverter-Container > h1 {
    color: var(--papyrus);
    width: 644px;
    margin-right: auto;
    margin-left: auto;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
}

.HowToUseConverter-Container > p {
    color: var(--papyrus);
    margin-left: auto;
    margin-right: auto;
    width: 542px;
    padding-top: calc(var(--padding-top)/2);
    padding-bottom: calc(var(--padding-bottom)/2);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
}

.HowToUseConverter-Container > div {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 768px) {
    .HowToUseConverter-Container{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }

    .HowToUseConverter-Container > h1 {
        width: 100%;
        padding-left: 45px;
        padding-right: 45px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;        
    }
    
    .HowToUseConverter-Container > p {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 0;
        padding-top: calc(var(--padding-top-mobile)/2);
        padding-bottom: calc(var(--padding-bottom-mobile)/2);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }
    
}