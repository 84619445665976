.TitleSection2-body{
    background-color: var(--moss);
    padding: auto;
    text-align: center;
}

.TitleSection2-body-flex{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    margin: auto;
}

.TitleSection2-body-flex-title{
    margin: 0;
    padding: 0;
    font-weight: 800;
    color: var(--papyrus);
    font-size: 72px;
    text-align: center;
}

.TitleSection2-body-flex-description{
    margin-left: auto;
    margin-right: auto;
    width: 1089px;
    font-weight: 400;
    color: var(--papyrus);
    font-size: 18px;
    text-align: center;
    padding-top: calc(var(--padding-top)/2);
}

@media screen and (max-width: 1089px){
    
    .TitleSection2-body{
        padding-top: 0px;
    }

    .TitleSection2-body-flex{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }

    .TitleSection2-body-flex-title{
        padding-top: 0px;
        font-weight: 800;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        min-width: 311px;
        width: fit-content;
        margin: auto;
    }

    .TitleSection2-body-flex-description{
        width: 311px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 0px;
        margin: 0px;
        padding-top: calc(var(--padding-top-mobile)/2);
    }
}
