.Art-Container-Card{
    width: 1200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.Art-Container-Card > div {
    margin-top: var(--padding-top);
    width: 350px;
    min-height: 504px;
    height: fit-content;
    padding-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    outline: 1px solid rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
    border-radius: 2px;
}


.Art-Container-Card > div > div > img {
    width: 350px;
    height: 350px;
    left: -2px;
    object-fit: cover;
    border-radius: 2px  2px  0px 0px;
}

.ant-image-mask{
    border-radius: 2px  2px  0px 0px !important;
}

.Art-Container-Card-Author{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #37402C;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
}

.Art-Container-Card-Text{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #37402C;
    padding-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
}

.Art-Container-Card-Country{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'salt' on, 'liga' off;    
    color: rgba(0, 0, 0, 0.45);
    padding-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
}


/* style = {{ , padding:'40px', width: '300px' }} */
/* @media :-moz-full-screen */
@media screen and (max-width: 1190px) {
    
    .Art-Container-Card{
        width: 95vw;
    }

    .Art-Container-Card > div {
        margin: calc(var(--padding-top-mobile));
    }
}

    