.Career-Container {
    max-width: 1062px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 45px;
    padding-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    border: 1px #37402C4D solid;
    transition: all 200ms ease-in-out;
}

.Career-Container:hover{
    box-shadow: 0px  10px 20px 0px rgba(0, 0, 0, 0.25);

}

.Career-Container > span{
    margin: 0;
    padding: 0;
}


.Career-Container-Date {
    color: rgba(55, 64, 44, 0.6);
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
    padding: 0;
    margin: 0;
}

.Career-Container-Title {
    color: var(--moss);
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    margin-top: 8px;
    margin-bottom: 8px;
}

.Career-Container-TypeContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    border-radius: 25px;
    width: fit-content;
    height: fit-content;
    background-color: var(--sunset-orange);
}

.Career-Container-Type {
    color: #ECE5D1;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
    margin: 0;
}

.Career-Container-Description {
    color: var(--moss);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    margin-top: 32px;
    margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
    .Career-Container {
        margin-left: 16px;
        margin-right: 16px;
    }    
}