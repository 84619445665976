@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.reactMarkDown{
    font-family: 'Inter', sans-serif;
    color: var(--moss) !important;
}


.reactMarkDown > * {
    font-family: Inter !important;
    color: var(--moss) !important;
}


.reactMarkDown h1 {
    color: var(--moss);
    font-family: Inter;
    font-size: 72px;
    font-weight: 800;
    line-height: 98px;
    letter-spacing: 0em;
}

.reactMarkDown h2 {
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
}

.reactMarkDown h3 {
    font-family: Inter;
    font-size: 40px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
}

.reactMarkDown h4 {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
}

.reactMarkDown h5 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
}

.reactMarkDown code {
    font-family: Inter;
    color: var(--moss-animation);

}

.reactMarkDown a {
    color: var(--sunset-orange);
}

.reactMarkDown a:hover {
    color: var(--moss-animation);
}