.MainBody-Top{
    display: flex;
    flex-direction: column;
    background-color: var(--moss);
    margin-bottom: var(--padding-bottom);
}

.MainBody-Top-Content{
    padding-left: 24px;
    padding-right: 24px;
    padding-top: calc(var(--padding-bottom) - 24px); /* 128px */
    padding-bottom: var(--padding-bottom);
    max-width: 1035px;
    margin-left: auto;
    margin-right: auto;
}

.MainBody-Top-Content > h1 {
    color: var(--papyrus);
    margin: 0px;
    width: fit-content;
    font-family: Inter;
    font-size: 72px;
    font-weight: 800;
    line-height: 98px;
    letter-spacing: 0em;
    text-align: center;
}

.MainBody-Top-Content > p {
    padding-top: 40px;
    margin: 0px;
    color: var(--papyrus);
    max-width: 1035px;
    margin-left: auto;
    margin-right: auto;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
}

.MainBody-Top-Content > div {
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.MainBody-Top > img {
    width: 100%;
    height: fit-content;
    object-fit: cover;
    background-color: var(--papyrus);
}

@media screen and (max-width: 1089px) {

    .MainBody-Top-Content{
        padding-top: var(--padding-bottom-mobile); /* 128px */
        padding-bottom: calc(var(--padding-bottom-mobile) * 2);
    }

    .MainBody-Top{
        margin-bottom: var(--padding-bottom-mobile);
    }

    .MainBody-Top{
        min-height: fit-content;
        height: fit-content;
    }

    .MainBody-Top-Content{
        height: fit-content;
    }

    .MainBody-Top-Content > h1 {
        font-family: Inter;
        font-size: 40px;
        font-weight: 800;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
    }   

    .MainBody-Top-Content > p {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }
    .MainBody-Top-Content > div {
        flex-direction: column;
        row-gap: 12px;
    }

    .MainBody-Top > img {
        padding: 0;
        margin: 0;
        height: fit-content;
    }

}