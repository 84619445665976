.MainBody-TestimonialsContainer{
    background-color: var(--papyrus);
    padding-top: calc(var(--padding-top)/2);
    padding-bottom: var(--padding-bottom);
}

.MainBody-TestimonialsContainer > h1 {
    padding-bottom: 0px;
    margin: 0;
    color: var(--moss);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
}

.MainBody-TestimonialsContainer-Item {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
}

.MainBody-TestimonialsContainer-Item > div {
    display: flex;
    flex-direction: row;
    gap: 10px
}

.MainBody-TestimonialsContainer-Item > div > h1 {
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 72px;
    font-weight: 800;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
}

.MainBody-TestimonialsContainer-Item > div > p{
    margin-top: 70px;
    color: var(--moss);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}

.MainBody-TestimonialsContainer-Item > h3{
    padding-top: 48px;
    color: var(--moss);
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
}

.MainBody-TestimonialsContainer-Item > h4{
    color: var(--moss);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
}

.MainBody-TestimonialsContainer > .ant-carousel .slick-dots li{
    background-color: #BCB7AA;
    width: 12.5px !important;
    height: 12.5px !important;
    border-radius: 50%
}
  
.MainBody-TestimonialsContainer > .ant-carousel .slick-dots li button{
    background-color: var(--moss);
    width: 12.5px !important;
    height: 12.5px !important;
    border-radius: 50% !important;
}
  
.MainBody-TestimonialsContainer > .ant-carousel .slick-dots li.slick-active button {
    background-color: var(--moss) !important;
    width: 12.5px !important;
    height: 12.5px !important;
    border-radius: 50%
}


.MainBody-TestimonialsContainer-Carousel{
    max-width: 1180px;
    height: fit-content;
    min-height: 350px;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    border-radius: 16px;
}

.MainBody-TestimonialsContainer-Carousel > .slick-dots {
    top: 330px
}

@media screen and (max-width: 1189px) {

    .slick-arrow{
        display: none !important;
    }

    .MainBody-TestimonialsContainer-Item > h3{
        padding-top: 24px;
        padding-bottom: 5px;
    }

    .MainBody-TestimonialsContainer > h1 {
        padding-bottom: 0px;
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
    }

    .MainBody-TestimonialsContainer-Carousel > .slick-dots {
        top: 370px;
    }
}

@media screen and (max-width: 1100px) {
    .MainBody-TestimonialsContainer-Carousel > .slick-dots {
        top: 300px;
    }
}

@media screen and (max-width: 768px) {
    .MainBody-TestimonialsContainer-Carousel > .slick-dots {
        top: 400px;
    }
}

@media screen and (max-width: 530px) {
    .MainBody-TestimonialsContainer-Carousel > .slick-dots {
        top: 500px;
    }
}