.Art-Gallery-Container{
    display: flex;
    flex-direction: column;
    background-color: var(--papyrus);
}

.Art-Gallery-Buttons-Container{
    margin-left: auto;
    margin-right: auto;    
    margin-top: var(--padding-top);
    margin-bottom: var(--padding-bottom);
}

.Art-Gallery-Buttons-Container > * {
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer !important;
}

.Art-Gallery-Buttons-Container > a > img {
    margin-bottom: 3px;
    cursor: pointer !important;
}

@media screen and (max-width: 1089px) {
    .Art-Gallery-Buttons-Container{  
        margin-top: var(--padding-top-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }


}