
.Footer{
    height: fit-content;
    width: 100%;
    background-color: var(--moss);
}

/* DESKTOP */
/* MAIN CONTAINER */

.FooterD{
    background-color: var(--moss);
    height: fit-content;
    width: 100%;
    padding-left: 110px;
    padding-right: 110px;
}

.FooterD-GroupOf-Cards{
    display: flex;
    flex-direction: row;
}

.FooterD-Image{
    display: none;
    height: 459px;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.FooterD-MainContainer{
    padding-top: 48px; /* 96px */
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 24px;
    gap: 0px
}

#FooterD-Card-Main{
    width: 255px;
    margin-right: auto;
}


.FooterD-Card{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 230px;
}

.FooterD-Card > img{
    width: 116.35px;
    height: auto;
    padding-bottom: 12px;
    padding-left: auto;
}

.FooterD-Card > div > h1{
    color: white;
    margin: 0px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 12px;
}

.FooterD-Card > p {
    max-width: 100%;
    color: var(--papyrus);
    margin: 0px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.FooterD-Card-Item {
    max-width: 180px;
}

.FooterD-Card-Item > span {
    padding-top: 0px;
    color: var(--papyrus);
    margin: 0px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    transition: all 200ms ease-in-out;
}

.FooterD-Card-Item > span:hover{
    color: var(--papyrus-animation) !important;
}

.FooterD-Card-SocialMedia {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.FooterD-Card-SocialMedia > a > img{
    width: 24px;
    height: 24px;
}

/* SECOND CONTAINER */

.FooterD-SecondContainer{
    width: 100%;
    display: flex;
    height: fit-content;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 26.85px;
    padding-bottom: 22.15px;
}

.FooterD-SecondContainer-Left{
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.FooterD-SecondContainer-Left > h1{
    color: var(--papyrus);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.FooterD-SecondContainer-Right{
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.FooterD-SecondContainer-Item > a, .FooterD-SecondContainer-Item {
    color: var(--papyrus);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    transition: all 200ms ease-in-out;
}

.FooterD-SecondContainer-Item > a:hover{
    color: var(--papyrus-animation) !important;
    
}

@media screen and (max-width: 1300px) {
    .FooterD-Card{
        width: 200px;
    } 
}

@media screen and (max-width: 1150px) {
    .FooterD-Card{
        width: 150px;
    } 
}

@media screen and (max-width: 1000px) {

    .FooterD{
        padding-left: 31px;
        padding-right: 31px;

    }

    .FooterD-MainContainer{
        flex-direction: column;
        margin-bottom: 0px;
    }

    .FooterD-Image{
        display: none;
    }

    
    .FooterD-GroupOf-Cards{
        gap: 24px;
        row-gap: 24px;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 24px !important;
        margin-bottom: 12px !important;
        justify-content: left;
    }

    .FooterD-Card{
        width: max-content;
    }


    .FooterD-Card:first-child{
        width: fit-content;
    }

    .FooterD-SecondContainer{
        flex-direction: column;
        padding-top: 0px;
        padding-bottom: 0px;
        justify-content: left;
    }
   
    .FooterD-Card{
        padding-bottom: 0px;
        padding: 0px !important;
        margin: 0px !important;
    }

    .FooterD-SecondContainer-Left{
        flex-direction: row;
        gap: 6px ;
        flex-wrap: wrap;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .FooterD-SecondContainer-Left > h1{
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

    .FooterD-Card-SocialMedia{
        padding-bottom: 24px;
    }
}

@media screen and (max-width: 768px) {
  
    .FooterD-GroupOf-Cards{
        justify-content: left;
    }
}