.Blog-Body-iFrame {
    width: 100%;
    height: 100vh;
    background-color: transparent;
    background-image: url("../../assets/spinner/Spinner.gif");  
    background-size: 100px; 
    background-position: center; 
    background-repeat: no-repeat;
}

.Blog {
    width: 100%;
    background-color: var(--papyrus);
}

.Blog-Body-Spinner {
    position: absolute;
    width: 100%;
}

.Blog-Body-Pagination {
    display: flex;
    width: 100%;
}

.Blog-Body-NoItems{
    min-height: 340px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-left: auto;
    margin-right: auto;
}

.Blog-Body-NoItems-FirstLine{
    color: var(--moss);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: calc(var(--padding-top)*2);
}

.Blog-Body-NoItems-SecondLine{
    color: var(--moss);
    font-family: Inter;
    font-size: 40px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: calc(var(--padding-bottom)*2 + 32px);
}

.Blog-Body-TypeSelctionContainer {
    display: flex;
    max-width: 1206px;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    margin-bottom: 32px;
    gap: 24px;
    padding-left: 24px;
    padding-right: 24px;
    flex-wrap: wrap;
}

.Blog-Body-TypeSelctionContainer-Item {
    background-color: transparent;
    color: var(--moss);
    border: 1px solid #37402C;
    border-radius: 25px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.Blog-Body-TypeSelctionContainer-Item-Selected {
    background-color: var(--moss);
    color: var(--papyrus);
    border: 1px solid #37402C;
    border-radius: 25px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.Blog-Drawer > * > * > * > * > * > div{
    color: var(--moss) !important;
    background-color: var(--papyrus);
}

@media screen and (max-width: 1080px) {

    .Blog-Body-NoItems{
        gap: 16px;
    }
    
    .Blog-Body-NoItems-FirstLine{
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 24px;
        padding-right: 24px;
    }
    .Blog-Body-NoItems-SecondLine{
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 24px;
        padding-right: 24px;
    }
}
    

