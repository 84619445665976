.MarkdownPage-MainContainer{
    display: flex;
    background-color: var(--papyrus) !important;
}

.MarkdownPage{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-top);
    background-color: var(--papyrus);
    max-width: 900px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: var(--papyrus);
    margin-left: auto;
    margin-right: auto;
}

.MarkdownPage h1{
    color: var(--moss);
    font-family: Inter;
    font-size: 72px;
    font-weight: 800;
    line-height: 98px;
    letter-spacing: 0em;
    text-align: center;
    background-color: var(--papyrus);    
    margin: 0;
}

.MarkdownPage h3 {
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    background-color: var(--papyrus);
    margin: 0;
    padding-top: 16px;
    padding-bottom: 40px;
}

.MarkdownPage h3 > span {
    margin: 0;
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    background-color: var(--papyrus);
}

.MarkdownPage img {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}

.MarkdownPage > .MdContainer {
    padding-top: 24px;
    padding-bottom: 124px;
    border-radius: 2px;
}

.MarkdownPage > .AuthorContainer {
       top: -100px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: var(--moss);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
}

.MarkdownPage > .AuthorContainer > img {
    position: relative;
    top: -100px;
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
}

.MarkdownPage > .AuthorContainer > h1 {
    position: relative;
    top: -100px;
    display: block;
    color: var(--papyrus);
    padding-top: -100px;
    font-family: Inter;
    font-size: 40px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
}

.MarkdownPage > .AuthorContainer > h3 {
    position: relative;
    top: -100px;
    display: block;
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
}

.MarkdownPage > .AuthorContainer > p {
    position: relative;
    display: block;
    color: var(--papyrus);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
    overflow: visible;
    margin-top: -100px;
    padding-bottom: 26px;
}

@media screen and (max-width: 1080px) {
    .MarkdownPage{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-top-mobile);
        max-width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }
}