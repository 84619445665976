.Instructions-Container{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    height: fit-content;
    display: flex;
    max-width: 978px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: center;
    background-color: var(--papyrus);
}

.Instructions-Container > h1 {
    color: var(--moss);
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-align: left;
    padding-bottom: calc(var(--padding-top)/2);
}

.Instructions-Container > ol{
    padding-left: 24px;
}


.Instructions-Container > ol > li {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .Instructions-Container{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }

    .Instructions-Container > h1 {
        width: 100%;
        padding-left: 82px;
        padding-right: 82px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: calc(var(--padding-top-mobile)/2);
    }

    .Instructions-Container > ol > li {
        margin-left: 24px;
        margin-right: 24px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
    }

}
