.Buttons-Unfilled{
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--papyrus);
}

.Buttons-Unfilled-Header{
    padding: 0px;
    height: 94px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--papyrus);
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Header:hover{
    background-color: var(--moss-animation) !important;
}


.Buttons-Unfilled-Header:hover > *{
    color: var(--ross) !important;
}


.Buttons-Unfilled-Header-Dropdown-Menu{
    display: flex;
    position: sticky;
    flex-direction: column;
    margin-top: -100px;
    border: 1px solid var(--moss-animation);
    border-top: 0px;
    transition: all 200ms ease-in-out;
    opacity: 0;
}

.Buttons-Unfilled-Header-Dropdown-Menu-Item{
    color: var(--papyrus);
    background-color: var(--moss);
    display: flex;
    justify-content: center;
    height: 75px;
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Header-Dropdown-Menu-Item > p{
    margin-top: auto;
    margin-bottom: auto;
    max-width: 124px;
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Header-Text{
    background-color: transparent !important;
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Header-Dropdown-Menu-Item:hover{
    background-color: var(--moss-animation);
    color: var(--ross);
}


.Buttons-Unfilled-Header > div {
    margin: 0px;
    display: flex;
    height: 94px;
    width: 100%;
    padding-left: 24px !important;
    padding-right: 24px !important;
    position: sticky;
    z-index: 10;
}

.Buttons-Unfilled-Header > div > p {
    margin: 0px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: auto;
    margin-bottom: auto;
    background-color: var(--moss);
}

.Buttons-Unfilled-Header > div > p > img{
    /* https://codepen.io/sosuke/pen/Pjoqqp */
    filter: invert(92%) sepia(17%) saturate(247%) hue-rotate(350deg) brightness(102%) contrast(85%);
    transform: rotate(0deg);
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Header:hover > span {
    display: flex;
    position: sticky;
    margin-top: 0px;
    flex-direction: column;
    opacity: 1;
}

.Buttons-Unfilled-Header:hover > div > p {
    background-color: var(--moss-animation);
}

.Buttons-Unfilled-Header:hover{
    background-color: transparent;
}

.Buttons-Unfilled-Header:hover > div {
    color: var(--ross);
    background-color: var(--moss-animation);
}

.Buttons-Unfilled-Header:hover > div > p > img{
    /* https://codepen.io/sosuke/pen/Pjoqqp */
    filter: invert(76%) sepia(30%) saturate(370%) hue-rotate(316deg) brightness(99%) contrast(98%); 
    transform: rotate(180deg);
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Outlined{
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    border: none;
    border-radius: 6px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    color: var(--moss);
    border: 1px solid var(--papyrus);
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Inversed{
    width: fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--moss);
    border-radius: 6px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid var(--moss);
    transition: all 200ms ease-in-out;
}

.Buttons-Unfilled-Inversed > img{
    padding-left: 8px;
    height: fit-content;
    margin-bottom: 12px;
}

.ButtonLinkArrow {
    font-family: Inter;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    letter-spacing: 0em !important; 
    text-align: center !important;
}



.Buttons-Unfilled-Inversed:hover, .Buttons-Unfilled-Inversed:focus{
    background-color: var(--papyrus-animation);
    border: 1px solid var(--moss-animation);
}

.Buttons-Unfilled-Inversed > span{
    color: var(--moss);
}

.Buttons-Filled{
    width: fit-content;
    height: fit-content;
    background-color: var(--papyrus);
    border: none;
    border-radius: 6px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    color: var(--moss);
    border: 1px solid var(--papyrus);
    transition: all 200ms ease-in-out;
}

.Buttons-Filled-Header{
    width: fit-content;
    height: fit-content;
    background-color: var(--papyrus);
    border: none;
    border-radius: 6px;
    margin-top: 23px;
    margin-bottom: 23px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    color: var(--moss);
    border: 1px solid var(--papyrus);
    transition: all 200ms ease-in-out;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    
}

.Buttons-Filled:hover,.Buttons-Filled:focus{
    background-color: var(--papyrus-animation);
    border: 1px solid var(--papyrus-animation);
}

.Buttons-Filled-Inversed{
    width: fit-content;
    height: fit-content;
    color: white;
    background-color: var(--moss);
    border: none;
    border-radius: 6px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--moss);
    transition: all 200ms ease-in-out;
}

.Buttons-Filled-Inversed:hover, .Buttons-Filled-Inversed:focus{
    background-color: var(--moss-animation);
    border: 1px solid var(--moss-animation);

}

.Buttons-Filled-Inversed > span{
    color: var(--papyrus)
}

.Buttons-Link{
    margin-top: auto;
    margin-bottom: auto;
}

.Buttons-Unfilled-Text{
    margin-left: 24px;
    margin-right: 24px;
    color: var(--papyrus);
}

.Buttons-Unfilled-Text > img {
    width: 14px;
    height: auto;
    margin-bottom: 2.5px;
    margin-left: 14px;
    color: var(--papyrus);
}

.Buttons-Filled-Text{
    color: var(--moss);
}


/* elly */
.Buttons-Line{
    
    background-color: transparent;
    text-align: center;   
    cursor: pointer;
    width: 24px;
    height: 26px;
    transition: all 200ms ease-in-out;
    border: none;
    border: 1px solid transparent;

}


.Buttons-Line:hover{
    background-color: var(--papyrus-animation);
    border-radius: 2px;
}

.Buttons-Line:focus{
    background-color: var(--papyrus-animation);
    border: 1px solid var(--moss);
    border-radius: 2px;
}

.Buttons-Line-Focused{
    text-align: center;   
    cursor: pointer;
    width: 24px;
    height: 26px;
    transition: all 200ms ease-in-out;
    border: none;
    background-color: var(--papyrus-animation);
    border: 1px solid var(--moss);
    border-radius: 2px;
}



.Buttons-Right-Arrow, .Buttons-Left-Arrow{
    width: 24px;
    height: 26px;
    border: none;
    background-color: transparent;
}

.Buttons-Right-Arrow, .Buttons-Left-Arrow > img{
    margin: auto;
}

.Buttons-Filled-Header-Animated{
    width: 100px;
    height: 40px;
    background-color: var(--papyrus);
    border: none;
    border-radius: 6px;
    margin-top: 25px;
    margin-bottom: 23px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    color: var(--moss);
    border: 1px solid var(--papyrus);
    transition: all 200ms ease-in-out;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    transition: all 200ms ease-in-out;
}

.Buttons-Filled-HeaderContainer-Animated:hover  > .Buttons-Filled-Header-Animated {
    width: 150px;
}

.Buttons-Filled-HeaderContainer-Animated:hover > * > .Buttons-Filled-Header-Animated-Preview {
    color: var(--papyrus);
    height: 0;
    transition: all 200ms ease-in-out;;
}


.Buttons-Filled-Header-Animated-HiddenText {
    height: 0px;
    color: transparent;
    display: block;
    transition: all 0ms ease-in-out;;
    text-overflow: ellipsis;
    white-space: nowrap; 
    margin: 0px;
    padding: 0px;
    line-height: fit-content;
    z-index: -100;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
}

.Buttons-Filled-Header-Animated-Preview {
    display: block;
    color: var(--moss);
    height: fit-content;
    transition: all 200ms ease-in-out;;
    text-overflow: ellipsis;
    white-space: nowrap; 
    margin: 0px;
    padding: 0px;
    line-height: fit-content;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
}


.Buttons-Filled-HeaderContainer-Animated:hover > * > .Buttons-Filled-Header-Animated-HiddenText {
    display: block;
    height:  fit-content;
    color: var(--moss);
    transition: all 100ms ease-in-out;;
}


@media screen and (max-width: 1600px) {
    .Buttons-Unfilled-Header{
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 1089px){
    .FullLengthButton > button{
        width: 100% !important;
    }
    .FullLengthButton > * > button{
        width: 100% !important;
    }
}
