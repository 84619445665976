.Awareness-Description-Container{
    margin-top: var(--padding-top);
    margin-bottom: var(--padding-bottom);
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    background-color: var(--papyrus);
    color: var(--moss);
}

.Awareness-Description-Container > h1 {
    color: var(--moss);
    max-width: 644px;
    margin-left: auto;
    margin-right: auto;
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
    
}
.Awareness-Description-Container > p {
    max-width: 996px;
    margin-left: auto;
    margin-right: auto;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
}

@media screen and (max-width: 1080px) {
    .Awareness-Description-Container{
        margin-top: var(--padding-top-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }

    .Awareness-Description-Container > h1 {
        max-width: 100%;
        padding-left: 47px;
        padding-right: 47px;
        font-family: Inter;
        font-size: 28px;
        font-weight: 800;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;   
    }

    .Awareness-Description-Container > p {
        margin-top: 16px;
        max-width: 100%;;
        padding-left: 24px;
        padding-right: 24px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }
    
}
