.Funders-BackgroundContainer{
    background-color: var(--papyrus);
}

.Funders-Container{
    max-width: 1500px;
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--papyrus);
    color: var(--moss);
    padding-top: calc(var(--padding-top));
    padding-bottom: calc(var(--padding-bottom)*2);
}

.Funders-Container > h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    color: var(--moss);
    padding-bottom: calc(var(--padding-bottom)/2);
}

.Funders-Container > p {
    font-style: normal;
    font-weight: normal;
    width: 487px;
    font-size: 18px;
    line-height: 32px;
    margin: 0;
}

.Funders-Container-ImagesContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    gap: 75px;
    row-gap: 88px;
}

.Funders-Container-ImagesContainer > div {
    display: flex;
}

.Funders-Container-ImagesContainer > div > img {
    margin: auto;
}

.Funders-Container-ImagesContainer > div:nth-child(n){
    padding-left: auto;
    padding-right: auto
}


.Funders-Container-ImagesContainer > div >img {
    max-width: 254px;
    max-height: 121px;
}

/* .Funders-Carousel-Container {
    height: 200px;
} */

.Funders-Carousel-Container > img{
    padding-left: 59px;
    padding-right: 59px;
    margin: 0;
    max-width: fit-content;
    max-height: 300px;
}

.Funders-Container-ImagesContainer-Mobile{
    width: 0;
    height: 0;
    visibility: hidden;
}

.Funders-Container > button {
    margin-left: auto;
    margin-right: auto;
}



@media screen and (max-width: 1089px) {
    .Funders-Container{
        width: 100vw;
        overflow: hidden;
        text-align: center;
        padding-top: 73px;
        padding-bottom: 73px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .Funders-Container > h1 {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: calc(var(--padding-bottom)/2);
    }

    .Funders-Container > p {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }

    .Funders-Container-ImagesContainer{
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        visibility: hidden;
    }

    .Funders-Container-ImagesContainer-Mobile{
        position: relative;
        visibility: visible;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: fit-content;
        padding-top: 32px;
    }

    .Funders-Carousel-Container{
        display: flex;
    }

    .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li{
        background-color: #BCB7AA;
        width: 12.5px !important;
        height: 12.5px !important;
        border-radius: 50%
    }
      
    .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li button{
        background-color: var(--moss);
        width: 12.5px !important;
        height: 12.5px !important;
        border-radius: 50% !important;
    }
      
    .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li.slick-active button {
        background-color: var(--moss) !important;
        width: 12.5px !important;
        height: 12.5px !important;
        border-radius: 50%
    }

    .Funders-Carousel-Container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Funders-Carousel-Container > img{
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: a;
        max-height: 100px;
        max-width: 380px;
        margin-bottom: 68px;
    }

    /* .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li{
        background-color: #BCB7AA;

    }
    
    .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li button{
        background-color: #ECE5D1;
    
    } */
    
    /* .Funders-Container-ImagesContainer-Mobile > .ant-carousel .slick-dots li.slick-active button {
        background-color: #ECE5D1 !important;
        
    } */
}

@media screen and (max-width: 768px) {

    .Funders-Container{
        padding-top: calc(var(--padding-top-mobile));
        padding-bottom: calc(var(--padding-bottom-mobile));
    }
    
    .Funders-Container > h1 {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: calc(var(--padding-bottom-mobile)/2);
        margin: 0px;
    }

    .Funders-Container > p {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }   
}