.ContactUs-Body{
    background-color: var(--papyrus);
}

.ContactUs-Body-Form{
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    margin: 0 auto;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    padding-left: 16px;
    padding-right: 16px;
    gap: 18px;
}

.ContactUs-Body-Form > h1 {
    margin: 0px;
    color: var(--moss);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;
}

.ContactUs-Body-Form > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 42px;
}

.ContactUs-Body-Form > div > *{
    width: 100%;
}

.ContactUs-Body-Form-Field {
    min-height: 60px;
    width: 100%;
    border: 3px solid rgba(205, 205, 205, 1);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding: 8px;
}

.ContactUs-Body-Form-LargeField {
    min-height: 289px;
    width: 100%;
    border: 3px solid rgba(205, 205, 205, 1);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding: 8px;
}

.ContactUs-Body-Form-Field:focus, .ContactUs-Body-Form-LargeField:focus {
    outline: 2px solid var(--moss);
    border-radius: 1px;
  }

.ContactUs-Body-Form-Label {
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    /* since the gap is 18px, but we need 16px padding: */
    padding-bottom: -2px;

} 

.ContactUs-Body-Form-MicroLabel {
    color: var(--moss);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 8px;
}

.ContactUs-Body-Form > button {
    /* since the gap is 18px, but we need 32px margin/padding: */
    margin-top: 14px;
}

.ContactUs-Body-Form-SuccessMessage {
    padding-left: 18px;
    font-size: 14px;
    color: var(--moss-animation);
}

.ContactUs-Body-Form-ErrorMessage{
    padding-left: 18px;
    font-size: 14px;
    color: var(--sunset-orange);
}

@media screen and (max-width: 1100px) {

    .ContactUs-Body-Form{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }
    
    .ContactUs-Body-Form > h1 {
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;   
        /* since the gap is 18px, but we need 32px margin/padding: */
        margin-bottom: 32px;
    }

    .ContactUs-Body-Form > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 42px;
    }
}