.MainBody-NumbersContainer{
    background-color: var(--moss);
    color: var(--papyrus);
    margin-top: 24px;
    padding-top: var(--padding-top);
    padding-bottom: calc(var(--padding-bottom) + 4px);
    margin-bottom: calc(var(--padding-bottom));
}

.MainBody-NumbersContainer > h1 {
    padding: 0px;
    margin: 0px;
    color: var(--papyrus);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: fit-content;
    letter-spacing: 0em;
    text-align: center;
}

.MainBody-NumbersContainer-FlexBox {
    width: 100%;
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px
}

.MainBody-NumbersContainer-FlexBox-StatsItem{
    display: flex;
    width: 255px;
    min-height: 186px;
    flex-direction: column;
    border: none; 
    padding-bottom: 16px;
    border-bottom: 2px solid var(--papyrus);
}

.MainBody-NumbersContainer-FlexBox-StatsItem > h1 {
    color: var(--papyrus);
    font-family: Inter;
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;    
    margin: 0;
    padding: 0;
}

.MainBody-NumbersContainer-FlexBox-StatsItem > p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1089px) {

    .MainBody-NumbersContainer{
        padding-bottom: var(--padding-bottom-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }

    .MainBody-NumbersContainer > h1 {
        padding-left: 16px;
        padding-right: 16px;
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
    }
    
    .MainBody-NumbersContainer-FlexBox-StatsItem {
        border-bottom: none;
    }

    .MainBody-NumbersContainer-FlexBox-StatsItem > h1 {
        text-align: center;
    } 

    .MainBody-NumbersContainer-FlexBox-StatsItem > p{
        text-align: center;
    }

}