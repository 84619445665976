.Header-desktop{
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: var(--moss);
}

.Header-desktop-left{
    padding-left: 110px;
    margin-top: auto;
    margin-bottom: auto;
}

.Header-desktop-right{
    display: flex;
    flex-direction: row;
    visibility: visible;
    margin-left: auto;
    padding-right: 110px;
    height: 94px;
}




.Header-desktop h1{
  /* position: absolute; */
  color: white;
}

#P {
  margin-top: 2vh;
  margin-left: 1vh;
}

.Header-desktop-logo-img{
    margin-top: auto;
    margin-bottom: auto;
    height: 40px;
}

.Header-desktop-logo-mobile-img{
    display: none;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 10px;
    height: 40px;
}

.Header-desktop-button{
    margin-top: auto;
    margin-bottom: auto;
    /* !important until we figure out color scheme */
    color: #ECE5D1 !important;
}

.Header-desktop-button-donate{
  margin-top: auto;
  margin-bottom: auto;
  background-color: #ECE5D1; /*does not currently work*/
  color: black;
}

.Header-mobile{
    position: sticky;
    top: 0;
    display: flex;
    visibility: hidden;
    height: 0rem;
    background-color: #37402C;
    z-index: 1000;
}

.Header-mobile-logo{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: 16px;
}

.Header-mobile-logo > *{
    height: 28px;
}

.Header-mobile-logo-hidden{
    position: absolute;
    top: 10px;
    left: 24px;
    height: 28px;
}

.Header-mobile-icon{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 16px;
    margin-left: auto;
    height: 20px;
    cursor: pointer;
}


.ant-drawer-close{
    position: absolute;
    color: white !important;
    top: 14px;
    right: 24px;
    padding: 0 !important;
    margin: 0 !important;
    width: 20px;
    height: 20px;
}


.Header-desktop-right > span{
    margin-right: 6px;
}

.Header-mobile-donate {
    width: calc(100% - 48px);
    margin-left: auto;
    margin-right: auto;
}

.Header-mobile-donate > *{
    margin-right: 10px;
}

.Header-mobile-donate > * > * {
    height: 48px !important;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.ant-menu-submenu-arrow > *{
    width: 16px !important;
}

.ant-menu-title-content{
    color: var(--papyrus);
}

.ant-menu-title-content > *{
    color: var(--papyrus);
}

.ant-menu-item-selected > *{
    color: var(--moss-animation) !important;
}

.ant-menu-item-selected > * > * {
    color: var(--moss-animation) !important;
}


.ant-drawer-header-close-only {
    margin: 0;
    padding: 0;
    height: 48px;
    color: white !important;
    border-bottom: 0px !important;
}
.ant-drawer-body{
    color: white;
    padding: 0 !important;
    margin: 0 !important;
}

.ant-drawer-body > div > .Buttons-Link > .Buttons-Filled{
    width: calc(100vw - 48px);
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 14px;
    margin-bottom: 14px;
}

.ant-menu-sub{
    background-color: rgba(236, 229, 209, 0.08) !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    letter-spacing: 0em !important;
    text-align: left;
}

.ant-menu-item{
    height: 64px !important;
}

.ant-menu-item-active{
    color: rgba(249, 185, 179, 1) !important;
}
.ant-menu-submenu-arrow{
    width: 20px;
    height: 20px;
}
.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover {
    color: rgba(249, 185, 179, 1) !important;
}


.ant-menu-submenu-title{
    height: 64px !important;
}

.ant-menu-item-selected{
    color: var(--moss) !important;
    background-color: var(--papyrus) !important;
}
.ant-drawer-body > *{
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 32px !important;
    letter-spacing: 0em !important;
    text-align: left;
    color: white;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #37402C !important;
}


.ant-dropdown-menu-title-content{
    color: var(--moss) !important;
}

.ant-dropdown-arrow{
    visibility: hidden;
    border-color: var(--papyrus) !important;
}

.ant-dropdown-menu-item-active {
    background-color: white !important;
}

.Header-Desktop-Dropdown{
    width: 100% !important;; 
    top: -14px !important;
    border-radius: 0px !important;
    background-color: var(--papyrus) !important;   
}

.Header-Desktop-Dropdown > *{
    height: 75px;
    width: 100%;
}


@media screen and (max-width: 1400px){
    .Header-desktop-right{
        padding-right: 24px;
    }
    .Header-desktop-left{
        padding-left: 24px;
    }
}


@media screen and (max-width: 1250px){

    .Header-desktop{
        justify-content: space-evenly;
    }

    .Header-desktop-logo-img{
        display: none;
    }
    
    .Header-desktop-logo-mobile-img{
        display: block;
    }
    

   
}

@media screen and (max-width: 1150px){

    .Header-desktop-right > span{
        margin-right: 0px;
    }
    
    .Header-desktop{
        display: none;
    }

    .Header-desktop-logo-mobile-img{
        display: none;
    }

    .Header-desktop-right{
        visibility: hidden;
    }

    .Header-mobile{
        visibility: visible;
        height: 3rem;
    }
}
