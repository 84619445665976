.Shop-Body{
    background-color: var(--papyrus);
}

.Shop-Body > div {
    padding-top: var(--padding-top);
}

@media screen and (max-width: 1089px) {
    .Shop-Body > div {
        padding-top: var(--padding-top-mobile);
    }
}