.PangolinsFactsMain-Body{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    margin-left: auto;
    margin-right: auto;
    width: 1062px;
    background-color: var(--papyrus);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.PangolinsFactsMain-Body > img {
    border-radius: 12px;
    max-width: 540px;
    max-height: 540px;
}

.PangolinsFactsMain-Body > div {
    display: flex;
    flex-direction: column;
}

.PangolinsFactsMain-Body > div > h1 {
    font-family: Inter;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: left;
    color: var(--moss);
    margin-bottom: 0px;
}

.PangolinsFactsMain-Body-DesktopHeader{
    padding: 0px;
    margin: 0px;
}

.PangolinsFactsMain-Body > div > p {
    padding-top: calc(var(--padding-top)/2);
    margin-top: 0px;
    width: 444px;
    left: 48px;
    font-size: 18px;
    border-radius: nullpx;
}

.PangolinsFactsMain-Body-MobileHeader{
    margin: 0px;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    display: none;
}

@media screen and (max-width: 1080px) {

    .PangolinsFactsMain-Body{
        width: fit-content;
        padding-top: var(--padding-top-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }

    .PangolinsFactsMain-Body-DesktopHeader{
        display: none;
    }


    .PangolinsFactsMain-Body-MobileHeader{
        display: block;
        margin: 0px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 8px;
    }
    
    .PangolinsFactsMain-Body{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding-bottom: var(--padding-bottom-mob);
    }

    .PangolinsFactsMain-Body > img {
        border-radius: 12px;
        height: 64vw;
        width: auto;
    }
    .PangolinsFactsMain-Body > div > p {
        width: fit-content;
        height: fit-content;
        max-width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 24.5px;
        margin-bottom: 0px;
        text-align: center
    } 
    
    
}