.GenericLeftRight-Container{
    display: flex;
    padding-bottom: var(--padding-bottom);
    width: 1089px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around !important;
}


.GenericLeftRight-Container-Left{
    padding-left: 65px;
    padding-right: 65px;
    margin-top: auto;
    margin-bottom: auto;
}


.GenericLeftRightButtons {
    display: flex;
    flex-direction: row;
    gap: 10px
}

.GenericLeftRight-Container-Left > h5 {
    text-transform: uppercase;
    color: #F15621;
    margin-right: auto;
    width: fit-content;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
}

.GenericLeftRight-Container-Left > h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: fit-content;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--moss);
}

.GenericLeftRight-Container-Left > p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--moss);
    margin: 0;
    padding-bottom: 20px;
    padding-top: 18px;
}

.GenericLeftRight-Container-Right > p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--moss);
    margin: 0;
    padding-bottom: 20px;
    padding-top: 18px;
}

.GenericLeftRight-Container-Left > img {
    width: 467px;
    object-fit: scale-down;
    border-radius: 12px !important;
}

.GenericLeftRight-Container-Right > img {
    width: 467px;
    object-fit: scale-down;
    border-radius: 12px !important;
}


.GenericLeftRight-Container-Left > h1 {
  margin: 0;
  padding: 0;
}

.GenericLeftRight-Container-Right > h1 {
    margin: 0;
    padding: 0;
}



.mobilePangolinNewsHeadline{
  display: none;
}

.GenericLeftRight-MobileLabel{
    display: none;
}

.GenericLeftRight-MobileTitle{
    color: var(--moss);
    display: none;
}

@media screen and (max-width: 1089px) {

    .GenericLeftRight-Container-Left > h1 {
        margin: 0;
        padding-bottom: 24px;
      }
      
      .GenericLeftRight-Container-Right > h1 {
          margin: 0;
          padding-bottom: 24px;
      }
      

    .GenericLeftRight-Container{
        display: flex;
        padding-bottom: var(--padding-bottom-mobile);
    }


    .GenericLeftRight-DesktopTitle{
        display: none;
    }

    .GenericLeftRight-MobileTitle{
        display: block;
    }
    
    .GenericLeftRight-Container{
        flex-direction: column-reverse;
        width: 100vw;
        text-align: center;
    }

    #leftImage{
      flex-direction: column;
    }


    .GenericLeftRight-Container-Left > h5, .GenericLeftRight-Container-Right > h5 {
        display: none;
        padding: 0px;
        margin: 0px;

    }


    .GenericLeftRight-Container-Left{
        padding-left: 16px;
        padding-right: 16px;
    }

    .GenericLeftRight-Container-Right{
        padding-left: 16px;
        padding-right: 16px;
    }

    .GenericLeftRight-Container-Left > img{
        height: 64vw;
        width: auto;
        object-fit: scale-down;
        margin-left: auto;
        margin-right: auto;
    }

    .GenericLeftRight-Container-Right > img{
        height: 64vw;
        width: auto;
        object-fit: scale-down;
        margin-left: auto;
        margin-right: auto;
    }

    .GenericLeftRightButtons{
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-right: 0px;
    }

    .GenericLeftRightButtons-First{
        padding-bottom: 10px;
    }
    
    .GenericLeftRightButtons > div{
        width: 100%;
        padding-right: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    .GenericLeftRight-MobileLabel{
        text-transform: uppercase;
        color: #F15621;
        display: block;
        padding-bottom: 32px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 1px;
        text-align: center;
    }

    .GenericLeftRight-Container-Left > h1, .GenericLeftRight-Container-Right > h1 {
        margin-left: auto;
        margin-right: auto;
        margin: 0px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: fit-content;
        letter-spacing: 0em;
        text-align: center;
    }

    .GenericLeftRight-Container-Left > p, .GenericLeftRight-Container-Right > p {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 32px;
        padding-bottom: 32px;
        margin: 0px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }


    #mobilePangolinNewsHeadline{
      display: block;
    }
}
