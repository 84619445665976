.Generic-Pagination-Container{
    margin-left: auto;
    margin-right: auto;    
    margin-top: var(--padding-top);
    margin-bottom: var(--padding-bottom);
}

.Generic-Pagination-Container > * {
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer !important;
}

.Generic-Pagination-Container > a > img {
    margin-bottom: 3px;
    cursor: pointer !important;
}

@media screen and (max-width: 1080px) {
    .Generic-Pagination-Container{
        margin-top: var(--padding-top-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }
}
    