.Page404{
    background-color: var(--papyrus);
    width: 100%;
    min-height: calc(100vh - 93px - 471px);
    padding-bottom: calc(var(--padding-bottom)*3);
}

.Page404-Body{
    background-color: var(--papyrus);
    padding-top: calc(var(--padding-top)*3);
    width: 826px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    gap: calc(var(--padding-top)/1.5)
}

.Page404-Body > h1 {
    margin: 0;
    padding: 0;
    font-family: Inter;
    font-size: 128px;
    font-weight: 800;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: center;    
}

.Page404-Body > p {
    margin: 0;
    padding: 0;
    font-family: Inter;
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;    
}

.Page404-Body > div {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 768px) {
    .Page404-Body{
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }
    .Page404-Body > h1 {
        font-family: Inter;
        font-size: 96px;
        font-weight: 800;
        line-height: 80px;
        letter-spacing: 0em;
        text-align: center;
    }
    .Page404-Body > p {
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }
}
    
