@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  font-family: 'Inter', sans-serif;}

:root{
  --sunset-orange: #f15621;
  --moss: #37402c;
  --moss-animation: #545a46;
  --papyrus: #ece5d1;
  --papyrus-animation: #cfcbb7;
  --rose-tan: #f9b9b3;
  --canary-yellow: #f3d956;
  --ross: #f9b9b3;
  --padding-top: 48px;
  --padding-bottom: 48px;
  --padding-top-mobile: 24px;
  --padding-bottom-mobile: 24px;
}

body {
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; 
}

.mountedStyle {
  animation: inAnimation 200ms ease-in;
  animation-fill-mode: forwards;
}

.unmountedStyle {
  animation: outAnimation 200ms ease-out;
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (max-width: 768px) {

  .Pangolins-Buttons-Container{
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  
  .Pangolins-Buttons-Container > div > a > button {
    width: 327px !important;
    margin-left: 24px;
    margin-right: 24px;
  }
  .Pangolins-Buttons-Container > div > button {
    width: 327px !important;
    margin-left: 24px;
    margin-right: 24px;
  }
  .Pangolins-Buttons-Container > a > button {
    width: 327px !important;
    margin-left: 24px;
    margin-right: 24px;
  }
  .Pangolins-Buttons-Container > button {
    width: 327px !important;
    margin-left: 24px;
    margin-right: 24px;
  }
}
  

/* Ant carousel properties */
/* .ant-carousel .slick-dots li{
  background-color: #BCB7AA;
  width: 12.5px !important;
  height: 12.5px !important;
  border-radius: 50%
}

.ant-carousel .slick-dots li button{
  background-color: var(--moss);
  width: 12.5px !important;
  height: 12.5px !important;
  border-radius: 50% !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: var(--moss) !important;
  width: 12.5px !important;
  height: 12.5px !important;
  border-radius: 50%
} */
