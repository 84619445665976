.WhichPango-Container{
    background-color: var(--papyrus);
}

.WhichPango-Container-BtnContainer{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-top: calc(var(--padding-top)/2);
    padding-bottom: calc(var(--padding-bottom));
}

.WhichPango-Cards {
    margin-left: auto;
    margin-right: auto;
    width: 1134px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: calc(var(--padding-top));
}

.WhichPango-Cards > * {
    cursor: pointer;
    margin: 12px;
}

.WhichPango-Results{
    padding-top: calc(var(--padding-top));
    padding-bottom: calc(var(--padding-bottom));
    margin-left: auto;
    margin-right: auto;
    max-width: 1134px;
}

.WhichPango-Results > h1{
    text-align: center;
    font-family: Inter;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
}

.WhichPango-Results-Item {
    margin-left: auto;
    margin-right: auto;
}

.WhichPango-Share {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    padding-bottom: calc(var(--padding-bottom));
}

.WhichPango-Share > * {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.WhichPango-Share > h1 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;    
}

.WhichPango-Share > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 37.81px;
}

.WhichPango-Share-Image{
    cursor: pointer;
    height: 25px;
}

@media screen and (max-width: 1150px){
    .WhichPango-Cards {
        width: 100%;
        flex-direction: column;
        margin-top: calc(var(--padding-top));
    }

    .WhichPango-Cards > * {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .WhichPango-Container-BtnContainer{
        padding-top: calc(var(--padding-top-mobile));
        padding-bottom: calc(var(--padding-bottom-mobile)*2);
    }

    .WhichPango-Results{
        padding-top: calc(var(--padding-top-mobile));
        padding-bottom: calc(var(--padding-bottom-mobile));
        padding-left: 24px;
        padding-right: 24px;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }

    .WhichPango-Share {
        padding-bottom: calc(var(--padding-bottom-mobile)*2);
    }
}

