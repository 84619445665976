.ScaleConverter-Container{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    margin-left: auto;
    margin-right: auto;
    max-width: 586px;
    height: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--papyrus);
}

.ScaleConverter-Container > h1 {
    color: var(--moss);
    max-width: 644px;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px;
    padding-bottom: calc(var(--padding-bottom)/2);
}


.ScaleConverter-Container > button {
    margin-left: auto;
    margin-right: auto;
}

.ScaleConverter-Calculator-Desktop{
    display: flex;
    flex-direction: column;
    padding-left: 86px;
    padding-right: 86px;
    padding-top: 37px;
    padding-bottom: 37px;
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgba(55, 64, 44, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
}

.ScaleConverter-Calculator-Desktop > h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;    
}


.ScaleConverter-Calculator-Desktop-Buttons {
    padding-top: 32px;
    display: flex;
    flex-direction: row;   
}

.ScaleConverter-Calculator-Desktop-Buttons > *{
    margin-right: 12px;
}

.ScaleConverter-Calculator-Desktop-Input{
    display: flex;
    flex-direction: row;   
    width: fit-content;
    overflow: hidden;
    background-color: white;
    border-radius: 4px;
}

.ScaleConverter-Calculator-Desktop-Input > input{
    border-style: none;
    width: 196px;
    height: 35px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 4px;
}


.ScaleConverter-Calculator-Desktop-Input-ArrowsButtons{
    display: flex;
    flex-direction: column;
    background-color: var(--moss);
    width: 28px;
    height: 35px;
    
}

.ScaleConverter-Calculator-Desktop-Input-ArrowsButtons > button{
    display: flex;
    background-color: transparent;
    height: 17.5px;
    width: 28px;
    border: 0;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.ScaleConverter-Calculator-Desktop-Input-ArrowsButtons > button:hover{
    background-color: var(--moss-animation);
}

.ScaleConverter-Calculator-Desktop-Input-ArrowsButtons > button > img{
    margin: auto;
}

.ScaleConverter-Calculator-Desktop-Inputs{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
}

.ScaleConverter-Calculator-Desktop-Inputs > input{
    border-style: none;
    margin-right: 24px;
    width: 196px;
    height: 50px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    -webkit-appearance: none;
    border-radius: 4px;
}

.ScaleConverter-Calculator-Desktop-Inputs > div{
    border-radius: 4px;
    border: 1px solid var(--moss);
    overflow: visible;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    background-color: var(-moss);
}

.ScaleConverter-Calculator-Desktop-Inputs > div > button{
    width: 50px;
    height: 48px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    border: none;
    transition: all 200ms ease-in-out;
}

.ScaleConverter-Calculator-Desktop-Inputs > div > .ScaleConverter-Calculator-Desktop-Inputs-gButton{
    color: var(--moss);
    background-color: var(--papyrus);
    margin: 0;
    padding: 0;
}

.ScaleConverter-Calculator-Desktop-Inputs > div > .ScaleConverter-Calculator-Desktop-Inputs-kgButton{
    color: var(--papyrus);
    background-color: var(--moss);
    margin: 0;
    padding: 0;
}

.ScaleConverter-Calculator-Desktop-Inputs > div > .ScaleConverter-Calculator-Desktop-Inputs-kgButton:hover{
    background-color: var(--moss-animation);
}

.ScaleConverter-Calculator-Desktop-Inputs > div > .ScaleConverter-Calculator-Desktop-Inputs-gButton:hover{
    background-color: var(--papyrus-animation);
}


.ScaleConverter-Calculator-Desktop-Inputs > input:focus{
   outline: 1px solid var(--moss);
   border-radius: 4px
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ScaleConverter-Container-Second{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
    height: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--papyrus);
}

.ScaleConverter-Container-Second > div > h1 {
    color: var(--moss);
    max-width: 100%;
    padding-bottom: 0 !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 8px;    
}

.ScaleConverter-Container-Second > div > p {
    color: var(--moss);
    margin-left: auto;
    margin-right: auto;
    max-width: 730px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;

}


.ScaleConverter-Results {
    margin-left: auto;
    margin-right: auto;
}

.ScaleConverter-Results-RowHeaders {
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: right;
    padding-bottom: 24px;
    padding-right: 24px;
} 

.ScaleConverter-Results-ColumnHeaders {
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    min-height: 96px;
    padding-left: 24px;
    padding-right: 24px;
}

.ScaleConverter-Results > tr > svg {
    width: 22px !important;
    padding-left: 10px;
    padding-right: 10px;
}

.ScaleConverter-Results > tr > td {
    margin: 0;
    min-width: 255px;
    min-height: 96px;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 24px;
}

/* .ScaleConverter-Results{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 730px;
}
.ScaleConverter-Results > div {
    flex: 1 1 33%;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
}

.ScaleConverter-Results > div > h1 {
    margin: 0;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
}

.ScaleConverter-Results > div > h5 {
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
}

.ScaleConverter-Results-Columns{
    height: fit-content !important;
    margin-bottom: auto !important;
    font-weight: 600 !important;
}

.ScaleConverter-Results > div > span {
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
} */

@media screen and (max-width: 1089px) {
    .ScaleConverter-Container{
        margin-top: 0px !important;
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
        margin-left: auto;
        margin-right: auto;
    }

    .ScaleConverter-Container-Second{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }
    
    .ScaleConverter-Results-RowHeaders {
        font-size: 18px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: right;
        padding-bottom: 24px;
        padding-right: 24px;
    } 
    
    .ScaleConverter-Results-ColumnHeaders {
        font-size: 18px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        min-height: 96px;
        padding-left: 24px;
        padding-right: 24px;
    }
    
    .ScaleConverter-Results > tr > td {
        margin: 0;
        min-width: fit-content;
        min-height: 96px;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 64px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 24px;
    }
    
}

@media screen and (max-width: 768px) {
    .ScaleConverter-Container{
        margin-top: var(--padding-top-mobile);
        margin-bottom: var(--padding-bottom-mobile);
    }

    .ScaleConverter-Container > h1 {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 24px;
        padding-right: 24px;  
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;  
        padding-bottom: calc(var(--padding-bottom-mobile)/2);
    }
    
    .ScaleConverter-Container > p {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 32px;
        padding-bottom: 44px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }

    .ScaleConverter-Calculator-Desktop{
        border: none;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 24px;
        padding-right: 24px;
        margin-left: auto;
        margin-right: auto;
    }

    .ScaleConverter-Calculator-Desktop   > h5 {
        margin: 0;
        width: fit-content;
        padding-bottom: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
    }
    
    .ScaleConverter-Calculator-Desktop-Buttons{
        flex-direction: column;
        gap: 6px;
    }

    .ScaleConverter-Calculator-Desktop-Buttons > * > button{
        width: 190px;
        height: 48px;
    }

    .ScaleConverter-Calculator-Desktop-Inputs{
        width: 100%;
        justify-content: space-around;
    }

    .ScaleConverter-Calculator-Desktop-ValueInput-Measure{
        min-width: fit-content;
    }
    .ScaleConverter-Calculator-Desktop-Inputs > input{
        width: 100%;
    }

    .ScaleConverter-Calculator-Desktop-Buttons > *{
        margin-right: 0px;
    }


    .ScaleConverter-Calculator-Desktop > div > div:nth-of-type(2) > button{
        padding-bottom: 0;
        margin-bottom: 0;
        
    }

    .ScaleConverter-Container{
        margin-bottom: 0;
    }

    .ScaleConverter-Container-Second{
        width: 100vw;
    }

    .ScaleConverter-Container-Second > div > h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 8px;
    }
    
    .ScaleConverter-Container-Second > div > p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 32px;
    }

    .ScaleConverter-Results > tr > svg {
        width: 22px !important;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 24px;
    }

    .ScaleConverter-Results {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .ScaleConverter-Results-RowHeaders {
        font-size: 12px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: right;
        padding-bottom: 12px;
        padding-right: 12px;
    } 
    
    .ScaleConverter-Results-ColumnHeaders {
        font-size: 12px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        min-height: 96px;
        padding-left: 24px;
        padding-right: 24px;
    }
    
    .ScaleConverter-Results > tr > td {
        margin: 0;
        min-width: fit-content;
        min-height: 96px;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 24px;
    }

}

@media screen and (max-width: 400px) {

    .ScaleConverter-Results-RowHeaders {
        font-size: 10px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: right;
        padding-bottom: 12px;
        padding-right: 12px;
    } 
    
    .ScaleConverter-Results-ColumnHeaders {
        font-size: 10px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        min-height: 96px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .ScaleConverter-Results > tr > td {
        margin: 0;
        min-width: fit-content;
        min-height: 96px;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 24px;
    }


}