.Sitemap{
    background-color: var(--papyrus);
    min-height: calc(100vh - 94px - 330px);
}

.Sitemap-Body{
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
}

.Sitemap-Title{
    text-transform: lowercase;
}

.Sitemap-Title > li {
    font-size: 24px;
}

.Sitemap-Title > li > span {
    color: var(--moss);
}

.Sitemap-Title > li >  * {
    color: var(--moss-animation);
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize;
}

.Sitemap-Title > li > a:hover{
    color: var(--moss);
}

.Sitemap-Body{
    padding-left: 24px;
    padding-right: 24px;
    width: fit-content;
    min-width: 540px;
    margin-left: auto;
    margin-right: auto;
}

.Sitemap-Item > li {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    list-style: circle;
    margin-left: 32px;
}

.Sitemap-Item > li > * {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: var(--moss-animation);
    padding: 0;
    margin: 0;
}

.Sitemap-Item:hover > li > *{
    color: var(--moss);
}

@media screen and (max-width: 768px) {
    .Sitemap-Body{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
    }
}
    