.WhoWeAre-TeamContainer-Person{
    width: 340px;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.WhoWeAre-TeamContainer-Person > div {
    width: 340px;
    height: 440px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.WhoWeAre-TeamContainer-Person > div > img {
    width: 340px;
    height: 440px;
    border-radius: 2px;
    object-fit: cover;
}

.WhoWeAre-TeamContainer-Person > div > div{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: fit-content;
    letter-spacing: 0em;
    text-align: center;
    color: transparent;
    margin-top: auto;
    position: absolute;
    display: flex;
    width: 340px;
    height: 440px;
    border-radius: 2px;
    transition: all 200ms ease-in-out;
}

.WhoWeAre-TeamContainer-Person > div > div > p{
    margin-top: auto;
    margin-bottom: auto;
    padding: 16px;
    visibility: hidden;
}

.WhoWeAre-TeamContainer-Person > div > div:hover{
    visibility: visible;
    color: white;
    background-color: #000000a6; 
    /* Transparency: 65% (a6) */
} 

.WhoWeAre-TeamContainer-Person > div > div:hover > p{
    visibility: visible;
}

.WhoWeAre-TeamContainer-Person > h1 {
    color: var(--moss);
    font-family: Inter;
    font-size: 32px;
    font-weight: 800;
    line-height: fit-content;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 26px;
    margin: 0px;
    width: 100%;
}

.WhoWeAre-TeamContainer-Person > h5 {
    color: var(--sunset-orange);
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px;
    padding: 0px;
    width: 100%;
}

@media screen and (max-width: 1089px) {

    .WhoWeAre-TeamContainer-Person > h1 {
        font-family: Inter;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
    }

    .WhoWeAre-TeamContainer-Person > h5 {
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
    }

}