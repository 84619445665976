.CommunityConservation-Body{
    background-color: var(--papyrus);
    margin: 0;
    padding: 0;
    min-height: calc(100vh - 94px - 300px - 224.4px);
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.CommunityConservation-Body > div{
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
}

.CommunityConservation-Description{
    width: 768px;
    height: 100%;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    margin: auto;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
}

@media screen and (max-width: 1089px) {
    .CommunityConservation-Description{
        padding-top: var(--padding-top-mobile);
        padding-bottom: var(--padding-bottom-mobile);
        width: 100%;
        height: fit-content;
        padding-left: 24px;
        padding-right: 24px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
    }
}