.PangoCard-Container{
    display: flex;
    flex-direction: column;
    width: 543px;
    min-height: 614px;
    height: fit-content;
    background-color: transparent;
    border-radius: 12px;
    border: 1px #37402C4D solid;
    transition: all 200ms ease-in-out
}

.PangoCard-Container-SELECTED{
    border: 1px solid var(--moss-animation);
    background-color: var(--moss);
    color: var(--papyrus);
}

.PangoCard-Container-SELECTED > h1 {
    color: var(--papyrus);
}

.PangoCard-Container > h1 {
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    padding-top: 16px;
    padding-bottom: 68px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
}

.PangoCard-Container > img {
    width: fit-content;
    max-height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.PangoCard-Container > div {
    margin-left: auto;
    margin-right: auto;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.PangoCard-Container:hover{
    box-shadow: 0px  10px 20px 0px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1150px){
    .PangoCard-Container{
        width: calc(100% - 24px - 24px);
        margin-left: 24px;
        margin-right: 24px;
        height: fit-content;
        min-height: fit-content;
    }

    .PangoCard-Container > img {
        width: fit-content;
        max-width: 100%;
        max-height: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}